import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card,
  Card as MuiCard,
  CardActions,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography as MuiTypography,
} from "@mui/material";
import { fontSize, spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import {mascaraBoleto,formatCPFCNPJ} from "../../components/FormatacaoUtil"
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);



function PayPaymentAccountModuloTitulo({boletoData,setBoletoData}) {

  let { getApi,postApi } = useAuth();

const [loading, setLoading] = useState(false);

const Typography = styled(MuiTypography)(spacing);
const [amountToPay, setAmountToPay] = useState(0);
const [errorMsg, setErrorMsg] = useState(null);

const [description, setDescription] = useState(null);

const [clickBtn, setClickBtn] = useState(false);
const [paying, setPaying] = useState(false);

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const setValorItem= (val) =>{
  let res;
  if(String(val).includes("$") ){
    val = String(val).replace(/\D/g, "");
    setAmountToPay(val/100);
    res=val/100;
  }else{
    setAmountToPay(val);
    res=val;
  }

  if(boletoData.allowed_type.id==1 && res>boletoData.max_amount){
    alert("O valor é maior que o permitido.");
    setAmountToPay(boletoData.max_amount);
  }else if(boletoData.allowed_type.id==1 && res<boletoData.min_amount){
    alert("O valor é menor que o permitido.");
    setAmountToPay(boletoData.min_amount);
  }else if(boletoData.allowed_type.id==2 && res!=boletoData.total_amount){
    alert("O valor é diferenteo permitido.");
    setAmountToPay(boletoData.total_amount);
  }

}


const preparetoPay = async ()=>{
  
  setPaying(true);
  let response = await postApi("/payment/boleto/confirmation", {
    id:boletoData.id,
    amount:amountToPay,
    description:description?description:"Pagamento de contas"
  });
  setPaying(false);
  if(response){
    if(response.amount){
      alert(response.amount.toString())
      window.location.href="/app/pagamento-de-contas"
    }else if(response.id){
      alert(response.id.toString())
      window.location.href="/app/pagamento-de-contas"
    }else if(response.description){
      alert(response.description.toString())
      window.location.href="/app/pagamento-de-contas"
    }else if(response.error){
      alert(response.error.toString())
      window.location.href="/app/pagamento-de-contas"
    }
    
    let payment=response.payment;
    if(payment){
      if(payment.status.id==6){
        alert("O pagamento foi cancelado");
        window.location.href="/app/pagamento-de-contas"
      }
    }
    
  }
  setBoletoData(response.payment)

}

useEffect(() => {
  if(boletoData){
    setAmountToPay(boletoData.total_amount);
  }

}, [boletoData]);

  return (
    <React.Fragment>
       <Card>
      <CardContent>
        
        <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody> 
          {boletoData.barcode &&
          <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            Código de Barras
          </TableCell>
          <TableCell component="th" scope="row">
           {              
           mascaraBoleto(boletoData.barcode)
          }
          </TableCell>
          </TableRow>
          
          }
          {boletoData.digitable_line &&
          <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            Linha Digitável
          </TableCell>
          <TableCell component="th" scope="row">
           {              
           mascaraBoleto(boletoData.digitable_line)
          }
          </TableCell>
          </TableRow>
          
          }
        
          {boletoData.issuer.name &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Emissor
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.issuer.name}
              </TableCell>
          </TableRow>
          }
          
          {boletoData.payee_info.name &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row">
                Favorecido
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.payee_info.name} -  {formatCPFCNPJ(boletoData.payee_info.document)}
              </TableCell>
          </TableRow>
        }
         {boletoData.payer_info.name &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Pagador
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.payer_info.name} -  {formatCPFCNPJ(boletoData.payer_info.document)}
              </TableCell>
          </TableRow>
          }
         {boletoData.due_date &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Vencimento
              </TableCell>
              <TableCell component="th" scope="row">
              {new Date(boletoData.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}) }
              </TableCell>
          </TableRow>
          }
           {boletoData.total_amount &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Valor do Documento
              </TableCell>
              <TableCell component="th" scope="row">
               { parseFloat(boletoData.total_amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
          {boletoData.discount>0 &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Valor do desconto
              </TableCell>
              <TableCell component="th" scope="row">
              { parseFloat(boletoData.discount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
           {
           (boletoData.fine>0 || boletoData.interest_rate>0 ) &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Juros/Mora
              </TableCell>
              <TableCell component="th" scope="row">
              { parseFloat(boletoData.fine+boletoData.interest_rate).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               Total a pagar
              </TableCell>
              <TableCell component="th" scope="row">
              <TextField
               placeholder={"Valor Unitário"}
               variant="outlined"
               value={parseFloat(amountToPay).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
               onChange={x => setValorItem(x.target.value)}
               fullWidth
               required
               disabled={boletoData.allowed_type.id==2 || clickBtn==true}
               my={2}
             />
              </TableCell>
          </TableRow>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Descrição
              </TableCell>
              <TableCell component="th" scope="row">
              <TextField
               placeholder={"Descrição"}
               variant="outlined"
               value={description}
               onChange={x => setDescription(x.target.value)}
               disabled={clickBtn==true}
               fullWidth
               
               my={2}
             />
              </TableCell>
          </TableRow>
          
        </TableBody>
      </Table>
      <center>
        {!clickBtn &&
        <React.Fragment>
         
          <Button  size="medium" variant="contained" 
          onClick={e=> setClickBtn(true)}
        >Continuar
        </Button>
        <Button  size="medium" variant="contained" sx={{marginLeft:5}}
          onClick={e=>   window.location.href="/app/pagamento-de-contas"} color="info"
        >Voltar
        </Button>
        </React.Fragment>
        }
        {(clickBtn && !paying) &&
        <React.Fragment>
          <Button  size="medium" variant="contained" 
          onClick={e=> preparetoPay()}
        >Confirmar
        </Button>
        <Button  size="medium" variant="contained" color="error" sx={{marginLeft:5}}
          onClick={e=> setClickBtn(false)}
        >Cancelar
        </Button>
        </React.Fragment>
        }

            {paying && 
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress />
                  </Box>
                </Grid>
                }
           
       
      </center>

    </TableContainer>
  
      </CardContent>
      
    </Card>
    </React.Fragment>
  );
}

export default PayPaymentAccountModuloTitulo;
