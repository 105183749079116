import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  TableRow,
  TableBody
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";
import { Table } from "react-feather";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";


function AddNewClient(props) {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, invoice, open } = props;



const [loading, setLoading] = useState(false);


  useEffect(() => {
    console.log(invoice)
    setValueAmountPaid(invoice.amount)
  },[invoice]);

  const handleClose = () => {
    onClose(invoice);
  };


  let [amountMarkedPaid, setAmountMarkedPaid] =  useState(0)

  const HandleSubmit = async()=>{
      if(!amountMarkedPaid)
      {
        alert("Você precisa informar o valor pago.");
        return ;
      }
      setLoading(true);
      let response ;
      if(invoice.status["id"]!=7){
        response = await postApi("/invoice/marcar/pago/"+invoice.id, {
        amount:amountMarkedPaid
      });
      }else{
        response = await postApi("/invoice/update/amount_marked_paid/"+invoice.id, {
        amount:amountMarkedPaid
      });
    }
    if(response.success){
      invoice.amount_marked_paid=amountMarkedPaid;
      invoice.status["id"]=7;
      invoice.status["name"]="Marcado como pago";
      alert("O status da cobrança foi alterada com sucesso")
      handleClose(invoice)
    }else if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não tem autorização");
        setLoading(false)

        return ;
      }
    }else{
      if(response.error){
        alert(response.error)
      }else if(response.amount){
        alert(response.amount.toString())
      }else{
        alert("Um erro ocorreu, por favor, tente novamente.")
      }
    }


    setLoading(false);
  }
  
  const setValueAmountPaid= (val) =>{
    if(String(val).includes("$") ){
      val = String(val).replace(/\D/g, "");
      setAmountMarkedPaid(val/100);
    }else{
      setAmountMarkedPaid(val);
    }

  

  }


  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"xs"} >
      <DialogTitle id="simple-dialog-title" ><center>Atualize a cobrança como paga</center></DialogTitle>
       
          <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <CardContent>

              <Grid container spacing={6} justifyContent="center">
                <Grid item md={12} textAlign='center'>
                <TextField
                label={"Valor Pago"}
                  placeholder={"Valor Unitário"}
                  variant="outlined"
                  value={parseFloat(amountMarkedPaid).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                  onChange={e => setValueAmountPaid(e.target.value)}
                  fullWidth
                  required
                  my={2}
                />
                </Grid>
              
              </Grid>
            </CardContent>
          </Card>
          </Grid>
          </Grid>
     
        

        <Grid container spacing={0} marginLeft={2} marginRight={2}>
         
          <Grid item xs={12} >
          {loading && 
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>}
          <Box textAlign='right' marginTop={7} marginBottom={5} marginRight={3}>
              <Button variant='contained' disabled={loading} color="secondary" onClick={handleClose}>
               Fechar
              </Button>
              <Button variant='contained' disabled={loading} onClick={HandleSubmit} color="boot_success">
               Marcar como pago
              </Button>
          </Box>
       
     
          </Grid>
       
              
        </Grid>

                




    </Dialog>
  );
}


export default AddNewClient;
