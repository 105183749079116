import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { green } from "@mui/material/colors";

import { Box, Chip, Drawer as MuiDrawer, ListItemButton, FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { color } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import CreateSignature from "../../pages/signature/createsignature";
import CreatePix from "../../pages/Cobrancas/createpix";
import CreateCarne from "../../pages/Cobrancas/createcarne";
import CreateBoleto from "../../pages/Cobrancas/createboleto";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  padding-top:20px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;



const Sidebar = ({ items, showFooter = true, ...rest }) => {

  const { businessAccountDetails, getApi,postApi,Session_BusinessAccountId, ShowModalFunc,showModal  } = useAuth();


  const [general_business_account_id, setGeneralBusinessAccountId] = useState(window.localStorage.getItem(Session_BusinessAccountId));

  useEffect(() => {
    setGeneralBusinessAccountId(window.localStorage.getItem(Session_BusinessAccountId));
    console.log(general_business_account_id);
  }, []);

  const onChangeBsAccount=(general_business_account_id)=>{
    setGeneralBusinessAccountId(general_business_account_id);
    window.localStorage.setItem(Session_BusinessAccountId, general_business_account_id);
    alert("Você está usando "+businessAccountDetails.find(x=> x.id==general_business_account_id).fantasy_name);
    window.location.reload();

  }


    const handleCloseBox = (value) => {
    ShowModalFunc(null)
  };

  const [selectedValueBox, setSelectedValueBox] = React.useState([]);



  return (
    <Drawer variant="permanent" {...rest} >
      <Brand component={NavLink} to="/app">
        <Box ml={1}>
          <br></br>
        <img src="https://pubstore1.cachebank.com.br/logos/v2/Logobranca2.png" width="180" />
        </Box> 
      </Brand>
      <Grid 
        style={{ 
          paddingTop:20,
          backgroundColor:"#04225d",
          color:"white",
          borderBlockColor:"#04225d"
      }}>
        <FormControl size="small" sx={{ m: 1, minWidth: 120,
            backgroundColor:"#04225d",
            color:"white",
            borderBlockColor:"#04225d" }} fullWidth>
          <InputLabel id="demo-select-small-label"
          sx={{
            backgroundColor:"#04225d",
            color:"white",
            justifyContent:"center"
          }}>Conta de Négocio</InputLabel>
        <Select
        onChange={e=> onChangeBsAccount(e.target.value)}
          labelId="demo-select-small-label"
          label="Conta de Négocio"
          value={general_business_account_id}
          sx={{
            backgroundColor:"#04225d",
            color:"white",
            borderBlockColor:"#04225d",
            paddingTop:2,
            textAlign:"center",
            wordWrap:"break-word",
            fontSize:12
          }}
        >
          {businessAccountDetails.map((item) => (
          <MenuItem value={item.id} selected={general_business_account_id}>{item.id} - {item.name}</MenuItem>
          ))}
          
        </Select>
      </FormControl>
    </Grid>

    <CreateBoleto
      selectedValue={selectedValueBox}
      open={showModal=="emitr_boleto"}
      onClose={handleCloseBox}
      key={"boleto"+showModal}
    />
    <CreateCarne
      selectedValue={selectedValueBox}
      open={showModal=="emitr_carne"}
      onClose={handleCloseBox}
      key={"carne"+showModal}
    />

    <CreatePix
      selectedValue={selectedValueBox}
      open={showModal=="emitr_pix"}
      onClose={handleCloseBox}
      key={"pix"+showModal}
    />

    <CreateSignature
      selectedValue={selectedValueBox}
      open={showModal=="emitr_assinatura"}
      onClose={handleCloseBox}
      key={"assinatura"+showModal}
    />
    
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
