import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {ptBR} from 'date-fns/locale'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  FormLabel,
  FormGroup,
  Modal,
  TableBody,
  Table,
  TableContainer,
  Paper as MuiPaper,
  TableCell,
  TableRow
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, fontSize, height, spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

import { useNavigate } from "react-router-dom";
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.subtractDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
}

const Paper = styled(MuiPaper)(spacing);

function EditSignature(props) {
  
  
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

  const navigate = useNavigate();

  
  let [signatureId, setSignatureId] = useState('');
  let [due_day, setDueDay] = useState('');
  let [next_due_date, setNextDueDate] = useState('');

  let [applyFees, setApplyFees] = useState('');

  let [fine_modality, setFineModality] = useState(null);
  let [fine_value, setFineValue] = useState(null);
  let [interest_value, setInterestValue] = useState(null);
  let [interest_modality, setInterestModality] = useState(null);

  
  let [tabEdition, setTabEdition] = useState(1);


  let [statusId, setStatusId] = useState(null);

  let [applyDiscount, setApplyDiscount] = useState(false);
  let [discount_modality, setDiscountModality] = useState("percent");
  let [discount_value, setDiscountValue] = useState(null);
  let [discount_goal_days, setDiscountGoalDays] = useState(null);
  
  let [installments, setInstallments] = useState(-1);
  let [cycletype, setCycleType] = useState(1);
  
  let [reference, setReference] = useState(1);

  
  let [errorMsg, setErrorMsg] = useState(null);
  let [saving, setSaving] = useState(false);
  
  
  let { putApi,getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,ShowModalFunc } = useAuth();
 
  const { onClose, selectedValue, open, signatureM,reload } = props;
  

  useEffect(() => {
    if(signatureM){
      setSignatureId(signatureM.id);
      setDueDay(signatureM.due_day);
      setNextDueDate(dayjs(new Date(signatureM.next_due_date)));
      setStatusId(signatureM.status.id);
      setInstallments(signatureM.installments)
      setCycleType(signatureM.installments_cycle.id);
      setReference(signatureM.reference)

      if(signatureM.fine){
        setApplyFees(signatureM.fine?true:false)
        setInterestModality(signatureM.fine.interest_modality??'percent')
        setInterestValue(signatureM.fine.interest_value)

        setFineValue(signatureM.fine.fine_value)
        setFineModality(signatureM.fine.fine_modality??'percent')
      }else{
        setInterestModality('percent')
        setFineModality('percent')
      }
      if(signatureM.discount){
        setApplyDiscount(signatureM.discount?true:false)
        setDiscountModality(signatureM.discount.modality)
        setDiscountValue(signatureM.discount.value)
        setDiscountGoalDays(signatureM.discount.goal_days?signatureM.discount.goal_days:0)
      }
    }


  },[signatureM]);

  const setTInterestValue= (val) =>{

    let interest_value_valueTMP

    interest_value_valueTMP = String(val).replace(/\D/g, "");
  
    interest_value_valueTMP=interest_value_valueTMP/100;
    setInterestValue(interest_value_valueTMP)
  }

  const setTFineValue= (val) =>{

    let fine_value_valueTMP

    fine_value_valueTMP = String(val).replace(/\D/g, "");
  
    fine_value_valueTMP=fine_value_valueTMP/100;
    setFineValue(fine_value_valueTMP)
  }

  const setValorDesconto= (val) =>{

    let discount_valueTMP

    discount_valueTMP = String(val).replace(/\D/g, "");
  
    discount_valueTMP=discount_valueTMP/100;
    setDiscountValue(discount_valueTMP)
  }



  const handleClose = () => {
    onClose()
    reload();
    ShowModalFunc(null)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.isNativeApp?"90%":600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    overflowY: 'scroll',
    height:"94%",
    p: 4,
  };

  const fontSizeTexts=window.isNativeApp?10:13;

  
const HandleSubmit = async () => {
  setErrorMsg(null)
  setSaving(true)
  if(!checkValues()){
    setSaving(false)
    return false;
  }



  let submitPost={
    reference:reference,
    due_day:parseInt(due_day),
    next_due_date:next_due_date,
    installments:parseInt(installments),
    cycletype:parseInt(cycletype),
    status_id:statusId
  };

  if(applyFees==true){
    let fine_TMP={
      fine_modality:fine_modality,
      fine_value:parseFloat(fine_value),
      interest_modality:interest_modality,
      interest_value:parseFloat(interest_value),
      deadline_days:1,
    }
    submitPost.fine=fine_TMP;
  }

  if(applyDiscount==true){
    let discount_TMP={
      modality:discount_modality,
      value:parseFloat(discount_value),
      goal_days:parseInt(discount_goal_days),
    }
    submitPost.discount=discount_TMP;
  }


  let response = await putApi("/invoice/signature/"+signatureId,submitPost);

  response_run(response);

}


const checkValues= ()=>{
let valorTotal=signatureM.amount;

  if(applyFees && fine_value<=0 || fine_value>20){
    alert("A multa precisa ter de 0.01% até 20%");
  }else if(applyFees && interest_value<=0.01 || interest_value>20){
    alert("O juros ao mês precisa ter de 0.01% até 20%");
  }else if(applyFees && ( !interest_value || !interest_value)){
    alert("Os juros e multa devem ser preenchidos");
  }else if(!next_due_date){
    alert("A próxima fatura precisa ser definido.");
  }
  else if(!due_day){
    alert("O dia do vencimento precisa ser definido.");
  }
  else if(applyDiscount && discount_modality=='percent' && ( discount_value>95 || discount_value<=0.01)){
    alert("Você não pode colocar esta porcentagem de desconto");
  }else if(applyDiscount && discount_modality=='fixedvalue' && ( discount_value>=valorTotal || discount_value<=0.01)){
    alert("O desconto precisa ser menor que o valor total ou maior que R$ 0.01");
  }else if(applyDiscount && ( !discount_value)){
    alert("O valor do desconto deve ser preenchido");
  }
  else{
    return true;
  }
}


const response_run=(response)=>{
  setSaving(false);
  if(response.success){
    alert("Dados alterados")
    handleClose();
  }else{
    if(response.costumer_id){
      setErrorMsg(response.costumer_id.toString())
    }else if(response.first_due_date){
      setErrorMsg(response.first_due_date.toString())
    }else if(response.due_day){
      setErrorMsg(response.due_day.toString())
    }
    else if(response.reference){
      setErrorMsg(response.reference.toString())
    }else if(response.charges_types){
      setErrorMsg(response.charges_types.toString())
    }
    
    
    else if(response.itens){
      setErrorMsg(response.itens.toString())
    }else if(response.discount){
      if(response.discount.modality){
        setErrorMsg(response.discount.modality.toString())
      }
      if(response.discount.value){
        setErrorMsg(response.discount.value.toString())
      }
    
    }
    else if(response.fine){
      if(response.discount.deadline){
        setErrorMsg(response.fine.deadline.toString())
      }
      if(response.discount.fine_modality){
        setErrorMsg(response.fine.fine_modality.toString())
      }
      if(response.discount.fine_value){
        setErrorMsg(response.fine.fine_value.toString())
      }
      if(response.discount.interest_modality){
        setErrorMsg(response.fine.interest_modality.toString())
      }
      else if(response.fine.installments){
        setErrorMsg(response.fine.installments.toString())
      }
    }
    else if(response["itens.0.description"]){
      setErrorMsg("A descrição do item precisa ser preenchida")
    }else if(response["itens.0.qtd"]){
      setErrorMsg("A quantidade do item precisa ser preenchida")
    }
    else if(response.error){
      setErrorMsg( response.error.toString())
    }else{
      setErrorMsg( "Ocorreu um erro, tente novamente.")
    }
    setSaving(false)
  }
}


 

  return (
    <Modal

      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box sx={style}>

      <Typography variant="h6" component="inline">
          <center> Edição da Assinatura: <br></br>
          <small>{signatureM.id}</small>
          </center>
      </Typography>

      <Typography sx={{ fontSize: window.isNativeApp?12:14 }} mt={1} mb={2}>
          Detalhes da Assinatura
        </Typography>
      <Typography variant="caption"  color="text.secondary" gutterBottom mt={2}>
           Status Atual: {signatureM.status.name}
           <br/>
           Cliente: {signatureM.costumer.fullname} - {signatureM.costumer.tax_identifier}
      </Typography>
      
      <Grid container spacing={0}>

        <TableContainer component={Paper} >
       
          <Table  size="small" aria-label="a dense table">
            <TableBody>
            
            <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="referenceField"><strong>Referência</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <TextField
                  fullWidth
                  value={reference}
                  onChange={e=> setReference(e.target.value)}
                  id="referenceField"
                  label="Referência"
                />
                </TableCell>
              </TableRow>
             
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Dia do vencimento</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <TextField
                  fullWidth
                  type="number"
                  value={due_day}
                  onChange={e=> setDueDay(e.target.value)}
                  id="due_dayField"
                  label="Dia de cada vencimento"
                />
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <label for="next_due_dateField"><strong>Próxima Fatura</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  id="next_due_dateField"
                    label="Próxima fatura a ser gerada"
                    inputFormat="dd/MM/yyyy"
                    value={next_due_date?next_due_date:null}
                    minDate={new Date()}
                    onChange={setNextDueDate}
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
                    
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                <small>
                Data de vencimento da próxima fatura que será gerada</small>
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Status da Assinatura</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <Select required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status da assinatura"
                    fullWidth
                    value={statusId}
                    onChange={e=> setStatusId(e.target.value)}
                  >
                   <MenuItem value={1}>Ativado</MenuItem>
                   <MenuItem value={2}>Suspensa</MenuItem>
                   <MenuItem value={3}>Cancelado</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Ciclo da Parcela</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <Select required
                  fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ciclo da Parcela"
                    value={cycletype}
                    onChange={e=> setCycleType(e.target.value)}
                  >
                    <MenuItem value={1}>Mensal</MenuItem>
                        {/* <MenuItem value={2}>Semanal</MenuItem>
                        <MenuItem value={3}>Quinzenal</MenuItem> */}
                        <MenuItem value={4}>Trimensal</MenuItem>
                        <MenuItem value={5}>Semestral</MenuItem>
                        <MenuItem value={6}>Anual</MenuItem>
                   
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Qtd de Parcela</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                    <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Qtd de Parcelas"
                        onChange={e=> setInstallments(e.target.value)}
                        value={installments}
                      >
                      <MenuItem value={-1}>Indeterminado</MenuItem>
                        {
                   [...Array(72)].map((e, i) => (
                      i>1 &&
                      <MenuItem key={i} value={i}>{i} parcelas</MenuItem>
                   ))
                   }
                  </Select>
                </TableCell>
              </TableRow>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={(e, v) => setTabEdition(v)}
                value={tabEdition}
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab value={1} label="Juros e Multa" {...a11yProps(0)} />
                  <Tab value={2} label="Desconto" {...a11yProps(1)} />
              </Tabs>
              </Box>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, display:tabEdition==1?null:"none" }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <label for="applyFees"><strong>Aplicar Multa/Juros</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={applyFees}
                      onChange={e=> setApplyFees(!applyFees)}
                    />
                  }
                />
                </TableCell>
              </TableRow>
              {applyFees && 
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, display:tabEdition==1?null:"none"  }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Multa e Juros</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>

                  <Grid container justifyContent={"center"}>
                  <Grid item sm={12} xl={5} mb={window.isNativeApp?3:null}>
                  <FormControl  variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Multa</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        label="Multa"
                        
                        type={'number'}
                        step={0.01}
                        max={20}
                        onChange={e=> setTFineValue(e.target.value)}
                        value={parseFloat(fine_value).toFixed(2)}
                      />
                  </FormControl>
                  </Grid>
                  <Grid item sm={5} ml={window.isNativeApp?null:2}>
                  <FormControl  variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Juros</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        label="Multa"
                        
                        type={'number'}
                        step={0.01}
                        max={20}
                        onChange={e=> setTInterestValue(e.target.value)}
                        value={parseFloat(interest_value).toFixed(2)}
                      />
                  </FormControl>
                  </Grid>

                  </Grid>
                </TableCell>
              </TableRow>
              }


              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 },display:tabEdition==2?null:"none"  }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <label for="applyDiscount"><strong>Aplicar Desconto</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <FormControlLabel
                  control={
                    <Switch
                    id="applyDiscount"
                      checked={applyDiscount}
                      onChange={e=> setApplyDiscount(!applyDiscount)}
                      name="applyDiscount"
                    />
                  }
                />
                </TableCell>
              </TableRow>


              {applyDiscount &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, display:tabEdition==2?null:"none"  }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Desconto</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>

                  <Grid container>
                  <Grid item sm={12} mb={2}>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                  <InputLabel id="demo-simple-select-label">Tipo de Desconto</InputLabel>
                    <Select fullWidth required 
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Tipo de Desconto"
                      onChange={e=> setDiscountModality(e.target.value)}
                      value={discount_modality}
                    >
                      <MenuItem value={'percent'}>Porcentagem</MenuItem>
                      <MenuItem value={'fixedvalue'}>Valor Fixo</MenuItem>
                    </Select>
                  </FormControl>
                  </Grid>

                  <Grid item sm={5} >
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">{discount_modality=="percent"?'%':'Reais'} de desconto</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                      <InputAdornment position="end">
                        {discount_modality=="percent"?'%':'reais'}
                        </InputAdornment>}
                      label="Juros ao mês"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setValorDesconto(e.target.value)}
                      value={discount_modality=="fixedvalue"?parseFloat(discount_value).toFixed(2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):parseFloat(discount_value).toFixed(2)}
                    />
                  </FormControl>
                  </Grid>

                  <Grid item sm={5} ml={window.isNativeApp?null:2}>
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Dias antes de vencer</InputLabel>
                  <Select required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status da assinatura"
                    value={discount_goal_days}
                    onChange={e=> setDiscountGoalDays(e.target.value)}
                    sx={{
                      textAlignLast:"center"
                    }}
                  >
                   <MenuItem value={0}>Até o vencimento</MenuItem>
                   <MenuItem value={1}>1 dia antes</MenuItem>
                   {
                   [...Array(60)].map((e, i) => (
                      i>1 &&
                      <MenuItem key={i} value={i}>{i} dias antes</MenuItem>
                   ))
                   }
                  </Select>

               
                  </FormControl>
                  </Grid>

                  </Grid>
                </TableCell>
              </TableRow>

                  
             
            }
          

              



            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="subtitle2"  color="text.secondary" gutterBottom mt={2}>
           Está alteração irá ter efeito na próxima fatura a ser gerada.
      </Typography>
         
      

      </Grid>
      <center>
      <Button sx={{marginTop:5}}
        variant="contained"
        size="medium"
        color={'boot_primary'} 
        disabled={saving}
        onClick={e=> HandleSubmit()} 
      >
        Salvar Alteração
      </Button>
      </center>

      <Grid>
      <Button 
        variant="contained"
        size="small"
        color={'secondary'}  disabled={saving}
        onClick={e=> handleClose()}
      >
        Fechar
      </Button>
      </Grid>
      <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errorMsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
      </Box>
      </Modal>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default EditSignature;
