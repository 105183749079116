import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";

function AddNewClient(props) {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, open } = props;



  const handleClose = (e=null) => {
    onClose(e);
  };

  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');

  let [phone, setPhone] = useState('');

  let [is_user_address, setIsUserAddress] = useState(false);
  let [isEmail, setIsEmail] = useState(true);

  let [loadingcep, setLoadingCep] = useState(false);

  let [identifier, setIdentifier] = useState('');
  let [fullname, setFullname] = useState('');
  let [email, setEmail] = useState('');

  let [reference, setReference] = useState('');
  
let [city, setCity] = useState('');
let [stateCountry, setStateCountry] = useState('');
let [district, setDistrict] = useState('');
let [number, setNumber] = useState('');
let [street, setStreet] = useState('');
let [zipcode, setZipCode] = useState('');
let [complement, setComplement] = useState('');

const serachZipCode=async ()=>{
  setLoadingCep(true)
  let zipcodeTmp=String(zipcode).replace(/\.|\-/g, '');

  if(zipcodeTmp.length!=8){
    setLoadingCep(false) 
    return false;
  }
 
  const response = await getApi("/consultar/cep/"+zipcodeTmp);
  if(!response){
    return;
  }

  setCity(response.city);
  setStreet(response.street);
  setDistrict(response.district)
  setStateCountry(response.state)
  setLoadingCep(false) 
 
}


const checkValues=()=>{
  if(!fullname){
    alert("Você precisa preencher o nome completo");
  }else if(!email && isEmail){
    alert("Você precisa preencher o seu e-mail");
  }else if(!identifier){
    alert("Você precisa preencher o CPF ou CNPJ");
  }else if(!phone){
    alert("Você precisa preencher o telefone");
  }
  
  else if(is_user_address==false && !zipcode){
    alert("Você precisa informar o cep");
  }else if(is_user_address==false && !street){
    alert("Você precisa informar o logradouro");
  }else if(is_user_address==false && (!number && number==0)){
    alert("Você precisa informar o numero");
  }else if(is_user_address==false && !district){
    alert("Você precisa informar o bairro");
  }else if(is_user_address==false && !city){
    alert("Você precisa informar o cidade");
  }else if(is_user_address==false && !stateCountry){
    alert("Você precisa informar o estado");
  }else{
    return true;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }



   let response = await postApi("/costumer/create",{
    email:isEmail?email:"naotememail@cachebank.com.br",
    fullname:fullname,
    phone:phone.replace(/\D/g, ""),
    tax_identifier:String(identifier).replace(/\D/g, ""),
    is_address_user:is_user_address,

    zipcode:zipcode.replace(/\D/g, ""),
    street:street,
    number:number,
    district:district,
    city:city,
    state:stateCountry,
    reference:reference

   });
 
  response_run(response);

};
const navigate = useNavigate();


const response_run=(response)=>{
  if(response.success){
    alert("Cliente cadastrado com sucesso.");
    if(props.callbackCostumer){
      handleClose(response.costumer);
      return ;
    }
    handleClose();
    navigate("/meus-clientes/visualizar/"+response.costumer.id);

  }else{

    if(response.city){
      setErrorMsg(response.city.toString())
    }else if(response.state){
      setErrorMsg(response.state.toString())
    }else if(response.district){
      setErrorMsg(response.district.toString())
    }else if(response.street){
      setErrorMsg(response.street.toString())
    }else if(response.number){
      setErrorMsg(response.number.toString())
    }else if(response.complement){
      setErrorMsg( response.complement.toString())
    }else if(response.zipcode){
      setErrorMsg( response.zipcode.toString())
    }
    else if(response.country){
      setErrorMsg( response.country.toString())
    }
    else if(response.email){
      setErrorMsg(response.email.toString())
    }else if(response.fullname){
      setErrorMsg(response.fullname.toString())
    }else if(response.password){
      setErrorMsg(response.password.toString())
    }else if(response.tax_identifier){
      setErrorMsg(response.tax_identifier.toString())
    }else if(response.phone){
      setErrorMsg(response.phone.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}

  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Cadastre um novo cliente</center></DialogTitle>
       
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Nome Cliente *</label>
                  <TextField
                    id="fullname"
                    placeholder={"Nome ou razão social"}
                    variant="outlined"
                    value={fullname}
                    onChange={e => setFullname(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>

                <label for="my-input">CPF/CNPJ *</label>
                <InputMask
                  mask={String(identifier).replace(/\D/g, "").length<=11?"999.999.999-999":"99.999.999/9999-99"}
                  disabled={false}
                  maskChar="_"
                  placeholder="CPF/CNPJ"
                  value={identifier}
                  fullWidth
                  my={2}
                  onChange={e => setIdentifier(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cpf_cnpj"
                  type="text"
                  id="cpf_cnpj"
                  autoComplete="cpf_cnpj"
                  placeholder="CPF/CNPJ"
                />}
                </InputMask>

                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                {isEmail &&
                <React.Fragment>
                  <label for="my-input">Endereço de e-mail *</label>
                  <TextField
                    id="fullname"
                    placeholder={"E-mail"}
                    variant="outlined"
                    value={email}
                    fullWidth
                    onChange={e => setEmail(e.target.value)}
                    my={2}
                    type="email"
                  />
                </React.Fragment>
                }
                 <label for="my-input">Cliente não possui e-mail?</label>
                <Checkbox defaultChecked={!isEmail} onClick={e=> setIsEmail(!isEmail)} />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Telefone *</label>
                <InputMask
                  mask={ ( String(String(phone).replace(/\D/g, "")).substring(2,3) >=5 )?"(99) 9 9999-9999":"(99) 9999-9999"}
                  disabled={false}
                  maskChar="_"
                  placeholder="Telefone"
                  value={phone}
                  fullWidth
                  my={2}
                  onChange={e => setPhone(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  autoComplete="phone"
                  placeholder="Telefone"
                />}
                </InputMask>
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Referência</label>
                  <TextField
                    id="reference"
                    placeholder={"Referência do cliente"}
                    variant="outlined"
                    value={reference}
                    onChange={e => setReference(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Cadastrar endereço?</label>
                <Checkbox defaultChecked={!is_user_address} onClick={e=> setIsUserAddress(!is_user_address)} />

                </Grid>
              </Grid>

              {is_user_address==false &&
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                Endereço do cliente
              </Typography>
              <Grid container spacing={6}>
              <Grid item xs={6}>
           

                <InputMask
                  mask="99.999-999"
                  disabled={false}
                  maskChar="_"
                  label="Seu CEP"
                  value={zipcode}
                  required
                  onChange={e => setZipCode(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cep"
                  label="Informe o seu CEP"
                  type="text"
                  id="cep"
                  autoComplete="cep"
                />}
                </InputMask>
      
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={e => serachZipCode()} disabled={loadingcep} >Preencher</Button>
                  {loadingcep==true &&
                <LinearProgress />}
                </Grid>
                <Grid item xs={7}>
          
                  <TextField   required
                      fullWidth
                      name="street"
                      placeholder="Informe o seu Logradouro"
                      type="text"
                      id="logradouro"
                      autoComplete="logradouro"
                      value={street}
                      onChange={e => setStreet(e.target.value)}
                    />
                
                  </Grid>
                  <Grid item xs={5}>
              
                  <TextField   required
                      fullWidth
                      name="numero"
                      label="Nº"
                      type="text"
                      id="numero"
                      autoComplete="numero"
                      value={number}
                      onChange={e => setNumber(e.target.value)}
                    />
                
                  </Grid>


                  <Grid item xs={7}>
              
                  <TextField   
                      fullWidth
                      name="complement"
                      label="Complemento"
                      type="text"
                      id="Complemento"
                      autoComplete="Complemento"
                      value={complement}
                      onChange={e => setComplement(e.target.value)}
                    />
                
                  </Grid>
                  <Grid item xs={5}>
              
                  <TextField   required
                      fullWidth
                      name="bairro"
                      placeholder="Bairro *"
                      type="text"
                      id="bairro"
                      autoComplete="bairro"
                      value={district}
                      onChange={e =>setDistrict(e.target.value)}
                    />
                
                  </Grid>



                  <Grid item xs={7}>
              
              <TextField   required
                    fullWidth
                    name="Cidade"
                    placeholder="Cidade *"
                    type="text"
                    id="Cidade"
                    autoComplete="Cidade"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
              
                </Grid>
                <Grid item xs={5}>
            
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{stateCountry?"":"Estado *"}</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stateCountry}
                        onChange={e=> setStateCountry(e.target.value)}
                      >
                          {  estadosBrasileiros.UF.map(el => (

                          <MenuItem value={el.sigla}>{el.sigla}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
              
                </Grid>



              </Grid>
            </React.Fragment>}

            <Grid container justifyContent="center" >
                
        
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
             
            </Grid> 
            <Grid container justifyContent="space-between" sx={{marginTop:5}} >

              <Button variant="contained" onClick={handleClose} disabled={saving} color="boot_secondary"  >
                      Fechar
              </Button>
                <Button variant="contained" disabled={saving} color="primary" mt={3} sx={{marginLeft:3}} onClick={handleSubmit} >
                  Salvar informações
                </Button>
            </Grid>
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                




    </Dialog>
  );
}


export default AddNewClient;
