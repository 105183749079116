import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";


function EditableAddress({
  loadingAddress,
  is_user_address,
  setIsUserAddress,
  setZipCode,
  zipcode,
  serachZipCode,
  loadingcep,
  setStreet,
  street,
  setNumber,
  number,
  setComplement,
  complement,
  setDistrict,
  district,
  setCity,
  city,
  stateCountry,
  setStateCountry



}) {


  

  return (
    <React.Fragment>

              <Grid container justifyContent="center" >
                
              {loadingAddress && <Grid item>
             
                
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
                <Typography variant="h7" > Carregando dados de endereço</Typography>
                </Grid>}
              </Grid>
              {!loadingAddress && 
              <React.Fragment>

              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Cadastrar endereço?</label>
                <Checkbox checked={!is_user_address} onClick={e=> setIsUserAddress(!is_user_address)} />

                </Grid>
              </Grid>

              {is_user_address==false &&
              <React.Fragment>
             
              <Grid container spacing={6}>
              <Grid item xs={6}>
           

                <InputMask
                  mask="99.999-999"
                  disabled={false}
                  maskChar="_"
                  label="Seu CEP"
                  value={zipcode}
                  required
                  onChange={e => setZipCode(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cep"
                  label="Informe o seu CEP"
                  type="text"
                  id="cep"
                  autoComplete="cep"
                />}
                </InputMask>
      
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={e => serachZipCode()} disabled={loadingcep} >Preencher</Button>
                  {loadingcep==true &&
                <LinearProgress />}
                </Grid>
                <Grid item xs={7}>
          
                  <TextField   required
                      fullWidth
                      name="street"
                      placeholder="Informe o seu Logradouro"
                      type="text"
                      id="logradouro"
                      autoComplete="logradouro"
                      value={street}
                      onChange={e => setStreet(e.target.value)}
                    />
                
                  </Grid>
                  <Grid item xs={5}>
              
                  <TextField   required
                      fullWidth
                      name="numero"
                      label="Nº"
                      type="text"
                      id="numero"
                      autoComplete="numero"
                      value={number}
                      onChange={e => setNumber(e.target.value)}
                    />
                
                  </Grid>


                  <Grid item xs={7}>
              
                  <TextField   
                      fullWidth
                      name="complement"
                      label="Complemento"
                      type="text"
                      id="Complemento"
                      autoComplete="Complemento"
                      value={complement}
                      onChange={e => setComplement(e.target.value)}
                    />
                
                  </Grid>
                  <Grid item xs={5}>
              
                  <TextField   required
                      fullWidth
                      name="bairro"
                      placeholder="Bairro *"
                      type="text"
                      id="bairro"
                      autoComplete="bairro"
                      value={district}
                      onChange={e =>setDistrict(e.target.value)}
                    />
                
                  </Grid>



                  <Grid item xs={7}>
              
              <TextField   required
                    fullWidth
                    name="Cidade"
                    placeholder="Cidade *"
                    type="text"
                    id="Cidade"
                    autoComplete="Cidade"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
              
                </Grid>
                <Grid item xs={5}>
            
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{stateCountry?"":"Estado *"}</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stateCountry}
                        onChange={e=> setStateCountry(e.target.value)}
                      >
                          {  estadosBrasileiros.UF.map(el => (

                          <MenuItem value={el.sigla}>{el.sigla}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
              
                </Grid>



              </Grid>
            </React.Fragment>}
              
            </React.Fragment>
              
              
            }
          
          </React.Fragment>

  );
}


export default EditableAddress;
