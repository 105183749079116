import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, spacing } from "@mui/system";

import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import isValidDomain from "is-valid-domain";
import LogoMarkAllStoreComponent from "./logomark-AllStore";
import LanguageIcon from '@mui/icons-material/Language';
import ContactsIcon from '@mui/icons-material/Contacts';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 350px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function Public({
  websiteName,
  setWebSiteName,
  contactEmail,
  setContactEmail,
  websitSlogan,
  setWebSiteSlogan}) {
  return (
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Informações do negócio
        </Typography>

        <Grid container spacing={0}>
          <Grid item md={12}>
            <TextField
              id="Nome do seu Negócio"
              label="Nome do seu Negócio"
              variant="outlined"
              value={websiteName}
              onChange={e=> setWebSiteName(e.target.value)}
              fullWidth
              my={2}
            />

            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                label="Slogan do negócio"
                id="biography"
                variant="outlined"
                value={websitSlogan}
                onChange={e=> setWebSiteSlogan(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                label="E-mail de contato"
                id="biography"
                variant="outlined"
                value={contactEmail}
                onChange={e=> setContactEmail(e.target.value)}
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={4}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="https://store16.cachebank.com.br/43213152a5de22032efe0be784cf8f37.jfif"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                Definir a sua logo Marca
                </Typography>
              </label>
            </CenteredContent>
          </Grid> */}
        </Grid>

      </CardContent>
  );
}

function Private({
  domainName,
  setDomainType,
  domainType,
  domain,
  domainsAvailable,
  invalidDomainName,
  setDomainNameChnage,
  setDomain,
  authEmail,
  authIdentifier,
  setAuthIdentifier,
  setAuthEmail,
  createdAccessOnTrigger,
  setCreatedAccessOnTrigger,
  setAllowApp,
  allowApp
}) {
 

  
  return (
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Configuração do Portal do seu Cliente
        </Typography>

        <Grid container spacing={6} mt={2} mb={3}>
        <Grid item md={12} >
          <InputLabel  id="demo-simple-select-label">APP Minhas Faturas</InputLabel>

            <FormControl sx={{width:"49%"}} >
                <Select  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Meio de autenticação"
                  value={allowApp} 
                  onChange={e=> setAllowApp(e.target.value)}>
                  <MenuItem value={true}>Permitir Acesso</MenuItem>
                  <MenuItem value={false}>Não Permitir Acesso</MenuItem>
                </Select>
            </FormControl>
          </Grid>


          <Grid item md={12} >
          <InputLabel  id="demo-simple-select-label">Criar acesso ao cadastrar um cliente</InputLabel>

            <FormControl sx={{width:"49%"}} >
                <Select  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Meio de autenticação"
                  value={createdAccessOnTrigger} 
                  onChange={e=> setCreatedAccessOnTrigger(e.target.value)}>
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
            </FormControl>
          </Grid>

          <Grid item md={12} >
            <InputLabel  id="demo-simple-select-label" sx={{marginBottom:3}}>Metodo de autenticação</InputLabel>
            
            <FormControlLabel
              value={true}
              control={<Checkbox />}
              label="CPF/CNPJ"
              labelPlacement="top"
              checked={authIdentifier==true}
              onChange={e=> setAuthIdentifier(!authIdentifier)}
            />
            <FormControlLabel
              value={true}
              control={<Checkbox />}
              label="E-mail"
              labelPlacement="top"
              checked={authEmail==true}
              onChange={e=> setAuthEmail(!authEmail)}
            />
          </Grid>
       
        </Grid>


        <Typography variant="h7" gutterBottom>
          <strong>Dados de Acesso</strong>
        </Typography>
        <Grid container spacing={6}>
          
          <Grid item md={12}>
            
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Escolha o endereço do portal</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={domainType}
              onChange={e=> { 
               if( domain==0 && e.target.value==1){
                setDomainType(e.target.value)
                setDomain(1);
               }else{
                setDomainType(e.target.value)

               }
               }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Sub-domínio gratuito" />
              <FormControlLabel value={2} control={<Radio />} label="Meu próprio domínio" />
            </RadioGroup>
          </FormControl>


          </Grid>

        {domainType==1 &&
           <React.Fragment>
           
          <Grid item md={6}>
              <TextField
                id="state"
                label="Nome"
                variant="outlined"
                fullWidth
                value={domainName}
                onChange={e=> setDomainNameChnage(e.target.value)}
                sx={{width:"50%"}} 
              />
            
            <FormControl sx={{width:"50%"}} 
                my={0} >
                <Select  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="sub-domínios" 
                  value={domain}
                  onChange={e=> setDomain(e.target.value)}
                  >
                  {domainsAvailable.map(el => (
                    el.id>0 &&
                  <MenuItem value={el.id} selected={el.id==domain}>{el.name}</MenuItem>
                ))}
                </Select>
            </FormControl>
           </Grid>
      
            
           </React.Fragment>
           }

            {domainType==2 &&
              <React.Fragment>
              
              <Grid item md={6}>
                  <TextField
                    id="state"
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    value={domainName}
                    onChange={e=> setDomainNameChnage(e.target.value)}
                    sx={{width:"100%"}} 
                  />
              </Grid>
            </React.Fragment>
            }
       
            
           {
           domainName &&
           domainType ==1 &&
           <React.Fragment>
            <Grid item md={12}>
            <Typography >{invalidDomainName && "Domínio informado estar inválido"}</Typography>
            {invalidDomainName==false &&
            
            <Typography variant="body" color="textPrimary">O acesso do seu portal será   
            <a target="_blank" href={domainsAvailable.length>0 &&"https://"+ domainName+"."+ domainsAvailable.find(x=> x.id==domain).name} rel="noreferrer">
              <strong>
              {
                domainsAvailable.length>0&&" "+ domainName+"."+ domainsAvailable.find(x=> x.id==domain).name
              }
              
              </strong>
            </a>
            </Typography>
            }

             </Grid>
             
     
           </React.Fragment>
           }

                  
          {
           domainName &&
           domainType==2 &&
           <React.Fragment>
            <Grid item md={12} >
            <Typography >{invalidDomainName && "Domínio informado estar inválido"}</Typography>
            {invalidDomainName==false &&
            
            <Typography variant="body" color="textPrimary">O acesso do seu portal será <a target="_blank" href="">
              <strong>
              {
                domainsAvailable.length>0&&domainName
              }
              
              </strong>
            </a>
            </Typography>
            }

             </Grid>
             
     
           </React.Fragment>
           }
           <br></br>
          
         
        
           
    
        
 
           </Grid>
           {(invalidDomainName==false && domainType==2) &&
           <Grid item md={6} mt={3} mb={2}>
            <span>Você precisa criar um registro DNS:
                 <br></br> <strong>Tipo: </strong> CNAME
                 <br></br> <strong>Nome: {domainName} </strong>
                 <br></br> <strong>Valor: cname.vitrine.center</strong> 
                 <br></br> <br></br> Pode levar algumas horas para funcionar, após criar o registro.
             </span>
 
           </Grid>}
          

   
      </CardContent>
  );
}

function Contacts({contactList,setContactList} ) {

const [contactType, setContactType] = useState(1);
  const [contactValue, setContactValue] = useState('');
  const [keyS, setKeyS] = useState(1);


  const AddContact = ()=>{

    let tmpList=contactList;
    tmpList.push({
      id:tmpList.length+'_new',
      type:contactType,
      value:contactValue
    });

    setContactList(tmpList)
    setKeyS(keyS+1);

  }


  const RemoveContact = (value)=>{

    let tmpList=contactList;
    tmpList = tmpList.filter(function(item) {
        return item.id !== value.id
    })

    
    setContactList(tmpList)

  }

  return (
    <React.Fragment>
      <CardContent>
        <Typography variant="h6" gutterBottom>
         Midias sociais
        </Typography>

       


        <Typography variant="h7" gutterBottom>
          <strong>Meio de contato</strong>
        </Typography>
        <Grid container spacing={6}>
          
        <Grid item md={12}>
            
        <FormControl sx={{width:"20%"}} >
          <Select  required
            label="Meio de autenticação"
            value={contactType}
            onChange={e => setContactType(e.target.value)}
            >
            <MenuItem value={1}>Site</MenuItem>
            <MenuItem value={2}>E-mail</MenuItem>
            <MenuItem value={3}>Instagram</MenuItem>
            <MenuItem value={4}>Facebook</MenuItem>
            <MenuItem value={5}>Twitter</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="Nome do Negócio"
          label= {
            (    
               (contactType==1&&"Seu site") || 
               (contactType==2&&"E-mail de contato") || 
               (contactType==3&&"Seu @ do Instagram")|| 
               (contactType==4&&"Seu @ do Facebook")|| 
               (contactType==5&&"Seu @ do Twitter")
            )
       }
          variant="outlined"
          value={contactValue}
          onChange={e => setContactValue(e.target.value)}
        />
               
               <Button variant="outlined" color="info" sx={{height:'100%'}} onClick={e=> AddContact()}>
                 Adicionar
                </Button>
          
            </Grid>


          <Grid item md={12}>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" key={keyS}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Tipo</TableCell>
            <TableCell align="center">Valor</TableCell>
            <TableCell align="center">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
        {contactList.map(el => (
        <TableRow  >
              <TableCell align="center" >
              {
                     (    
                        (el.type==1&&"Site ") || 
                        (el.type==2&&"E-mail ") || 
                        (el.type==3&&"Instagram ")|| 
                        (el.type==4&&"Facebook ")|| 
                        (el.type==5&&"Twitter ")
                     )
                }
                {String(el.id).includes("_new") &&   
                <Typography variant="inherit" sx={{color:"red"}} >  Novo </Typography>}
              </TableCell>
              <TableCell align="center">{el.value}</TableCell>
              <TableCell align="center">
                <Button onClick={e=> RemoveContact(el)}>Excluir</Button>
              </TableCell>
            </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>

          </Grid>

  

                  
      
           <br></br>
          
         
        
           
    
        
 
           </Grid>

          

   
      </CardContent>
    </React.Fragment>
  )


}

function Settings() {
  let [authEmail, setAuthEmail] = useState(false);
  let [authIdentifier, setAuthIdentifier] = useState(false);
  let [contactEmail, setContactEmail] = useState('');
  let [websiteName, setWebSiteName] = useState('');
  let [websitSlogan, setWebSiteSlogan] = useState('');

  
  let [saving, setSaving] = useState(false);

  let { getStoreApi,putStoreApi,getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,businessAccount } = useAuth();

  let [domainsAvailable, setDomainsAvailable] = useState([]);
  let [domainType, setDomainType] = useState(1);

  let [domain, setDomain] = useState(1);
  let [domainName, setDomainName] = useState(null);
  
  let [createdAccessOnTrigger, setCreatedAccessOnTrigger] = useState(false);
  let [allowApp, setAllowApp] = useState(false);

  
  let [loading, setLoading] = useState(true);
 
  let [page, setPage] = useState('access');


  
  let [invalidDomainName, setInvalidDomainName] = useState(false);


  const setDomainNameChnage = async (value) => {
    if(domainType==1){

      value=String(value).replace('^\d+,([^,]+),.*','')
      
    }

    if(domainType==2){


        if(isValidDomain(value, {subdomain:true})){
          setInvalidDomainName(false);
        }else{
          setInvalidDomainName(true);
        }

  
    }

    setDomainName(value);
    

    return value;
  
  };

    let [contactList, setContactList] = useState([]);

    const setContactList2= (a)=>{
setContactList(a);
    }

  useEffect(() => {

    LoadDomainsAvaible();
    LoadVwConfig();
  },[]);
  
  useEffect(() => {

  },[contactList]);


  const LoadDomainsAvaible = async () => {
    
    let response = await getStoreApi("/list/domains");
    setDomainsAvailable(response.lista)
  
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);
    let response = await putStoreApi("/configuration",{
      website_name:websiteName,
      website_slogan:websitSlogan,
      contact_email:contactEmail,
      auth_identifier:authIdentifier,
      auth_email:authEmail,
      allow_app:allowApp,
      created_access_on_created_user:createdAccessOnTrigger,
      domain:{
        type:domainType,
        domain_id:domain,
        name:domainName
      },
      contacts:contactList
    });

    if(response.success){
      alert("Dados alterados com sucesso");
      LoadVwConfig();
    }
    setSaving(false);

    
  
  };

  const LoadVwConfig = async () => {
    
    let response = await getStoreApi("/configuration");
    let configuration=response.configuration;
    setAuthIdentifier(configuration["auth_identifier"]);
    setAuthEmail(configuration["auth_email"]);
    setCreatedAccessOnTrigger(configuration["created_access_on_created_user"]);
    
    setWebSiteName(configuration["website_name"]);
    setWebSiteSlogan(configuration["website_slogan"]);
    setContactEmail(configuration["contact_email"]);

    
    setAllowApp(configuration["allow_app"]);
    setLoading(false)

    if(configuration.domain){
      setDomainName(configuration.domain["name"]);
      setDomainType(configuration.domain["type"]);
      setDomain(configuration.domain["id"]);
    }
    if(configuration.contacts){
      setContactList(configuration.contacts)
    }
  };
  

  return (
    <React.Fragment>
      <Helmet title="Portal do Cliente" />

      <Typography variant="h3" gutterBottom display="inline">
      Configuração
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="#">
        Portal do Cliente
        </Link>
        <Typography>Configuração</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container >
        <Grid item xs={7} lg={7} sm={12}>
          <Card>
          {loading &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
          {!loading &&
          <React.Fragment>
          <Public 
            contactEmail={contactEmail} 
            setContactEmail={setContactEmail}
            setWebSiteName={setWebSiteName}
            setWebSiteSlogan={setWebSiteSlogan}
            websiteName={websiteName} 
            websitSlogan={websitSlogan}
          />
          
          <BottomNavigation
          sx={{backgroundColor:'#f3faf0'}}
            showLabels >
            <BottomNavigationAction label="Acesso" sx={{
                backgroundColor:page=='access' && "#e6e6e6"
            }} onClick={e=> setPage('access')} icon={<LanguageIcon />} />
            <BottomNavigationAction label="Contatos" icon={<ContactsIcon />} sx={{
                backgroundColor:page=='contacts' && "#e6e6e6"
            }} onClick={e=> setPage('contacts')} />
          </BottomNavigation>
        
          {page=='access' &&
          <Private 
          domainName={domainName} 
          setDomainType={setDomainType} 
          domainType={domainType} 
          domain={domain} 
          domainsAvailable={domainsAvailable} 
          invalidDomainName={invalidDomainName}
          setDomainNameChnage={setDomainNameChnage}
          setDomain={setDomain}
          authEmail={authEmail}
          authIdentifier={authIdentifier}
          setAuthIdentifier={setAuthIdentifier}
          setAuthEmail={setAuthEmail}
          createdAccessOnTrigger={createdAccessOnTrigger}
          setCreatedAccessOnTrigger={setCreatedAccessOnTrigger}
          setAllowApp={setAllowApp}
          allowApp={allowApp}
         />}


         {page=='contacts' && 
         <Contacts  
         contactList={contactList}
         setContactList={a => setContactList2(a)}
         />
         }

                 
          <CardContent>
          {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
            <Button variant="contained" onClick={handleSubmit} color="primary" disabled={saving || invalidDomainName} >
          Salvar Alteração
        </Button>
        </CardContent>
          </React.Fragment>
          
          }
         


     
          </Card>
        </Grid>
        <Grid item xs={4} md={4} sm={12}  >
        <Card >
            <LogoMarkAllStoreComponent />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
