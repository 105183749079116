import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import $  from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";

function AddNewClient(props) {

  $('#contacomdigito_cad_transferencia').mask("#0-0", {reverse: true});


  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,businessAccount } = useAuth();
  let [loadAssociatedBankList, setLoadingAssociatedBankList] = useState(true);
  let [associatedBankList, setassociatedBankList] = useState([]);


  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');


  let [identifier, setIdentifier] = useState('');
  let [holderType, setHolderType] = useState('sameholder');
  let [agency, setAgency] = useState();
  let [associatedBankId, setAssociatedBankId] = useState();
  let [accountType, setAccountType] = useState(1);

  let [business_name, setbusinessName] = useState('');
  let [email, setEmail] = useState('');

  

useEffect(() => {

  ChangeHolder("sameholder")
  LoadAssociatedBankList();
},[props]);

const ChooseAssociatedBank= (e,value)=>{
  setAssociatedBankId(value.id)
}

const LoadAssociatedBankList = async () => {
    
  setassociatedBankList([]);
  setLoadingAssociatedBankList(true);
  let response = await getApi("/payee_account/associated_banks");
  
  setassociatedBankList(response.lista);
  setLoadingAssociatedBankList(false);

};



const checkValues=()=>{
  if(!business_name){
    alert("Você precisa preencher o nome completo");
  }else if(!identifier){
    alert("Você precisa preencher o CPF ou CNPJ");
  }else if(!$("#contacomdigito_cad_transferencia").val()){
    alert("Você precisa preencher o numero da conta.");
  }else if(!agency){
    alert("Você precisa preencher os dados da agência.");
  }else if(!accountType){
    alert("Você precisa preencher o tipo da conta.");
  }else if(!associatedBankId){
    alert("Você precisa informar o nome do banco.");
  }
  
else{
    return true;
  }
}

const handleSubmit = async (event) => {
  
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }

   let response = await postApi("/payee_account/create",{
    associated_bank_id:associatedBankId,
    name:business_name,
    tax_identifier:identifier.replace(/\D/g, ""),
    business_name:business_name,
    agency:String(agency).padStart(4, '0'),
    account_number:String( $("#contacomdigito_cad_transferencia").val()),
    account_type:accountType


   });
 
  response_run(response);

};
const navigate = useNavigate();


const response_run=(response)=>{
  if(response.success){
    alert("Conta bancária cadastrado com sucesso.");
    window.location.reload(false);
  }else  if(response.message){
    if(response.message=='This action is unauthorized.'){
      setErrorMsg("Você não tem permissão para isso.")
      setSaving(false)
      return ;
    }
  }else{

    if(response.city){
      setErrorMsg(response.city.toString())
    }else if(response.state){
      setErrorMsg(response.state.toString())
    }else if(response.district){
      setErrorMsg(response.district.toString())
    }else if(response.street){
      setErrorMsg(response.street.toString())
    }else if(response.number){
      setErrorMsg(response.number.toString())
    }else if(response.complement){
      setErrorMsg( response.complement.toString())
    }else if(response.zipcode){
      setErrorMsg( response.zipcode.toString())
    }
    else if(response.country){
      setErrorMsg( response.country.toString())
    }
    else if(response.email){
      setErrorMsg(response.email.toString())
    }else if(response.business_name){
      setErrorMsg(response.business_name.toString())
    }else if(response.password){
      setErrorMsg(response.password.toString())
    }else if(response.phone){
      setErrorMsg(response.phone.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}


const ChangeHolder=(e)=>{
  if(e=="sameholder"){
    setbusinessName(businessAccount.company_name)
    setIdentifier(businessAccount.identifier)
  }else{
    setbusinessName(businessAccount.company_name)
    setIdentifier(businessAccount.identifier)
  }
  
  setHolderType(e);
}



  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Cadastre uma nova conta bancária</center></DialogTitle>
       
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

            <Grid container spacing={6}>
                <Grid item md={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Titularidade</FormLabel>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={holderType}
                    onChange={e=> ChangeHolder(e.target.value)}
                  >
                    <FormControlLabel value="sameholder" control={<Radio />} label="Mesma titularidade" />
                    {/* <FormControlLabel value="anotherholder" control={<Radio />} label="Outra titularidade" /> */}
                  </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>

              {
                holderType!="sameholder" &&
                <React.Fragment>
                  
                  <Grid container spacing={6}>
                <Grid item lg={12}  style={{width:window.isNativeApp&&"100%"}}>
                <label for="my-input">Nome Titular</label>
                  <TextField
                    id="businessName"
                    placeholder={"Nome ou razão social"}
                    variant="outlined"
                    value={business_name}
                    onChange={e => setbusinessName(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>

                <label for="my-input">CPF/CNPJ *</label>
                <InputMask
                  mask={String(identifier.replace(/\D/g, "")).length<=11?"999.999.999-99":"99.999.999/9999-99"}
                  disabled={false}
                  maskChar="_"
                  placeholder="CPF/CNPJ"
                  value={identifier}
                  fullWidth
                  my={2}
                  onChange={e => setIdentifier(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cpf_cnpj"
                  type="text"
                  id="cpf_cnpj"
                  autoComplete="cpf_cnpj"
                  placeholder="CPF/CNPJ"
                />}
                </InputMask>

                </Grid>
              </Grid>
              
                </React.Fragment>
                }
                
              
                <Grid container spacing={6}>
                <Grid item md={12}    style={{width:window.isNativeApp&&"100%"}}>
                <Autocomplete
                  id="country-select-demo"
                  fullWidth
                  disabled={loadAssociatedBankList}
                  options={associatedBankList}
                  autoHighlight
                  getOptionLabel={(option) => option.code+" - "+option.name}
                  onChange={ChooseAssociatedBank}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.code} - {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Digite o nome do banco"
                    />
                  )}
               
                />
              {loadAssociatedBankList &&
                <LinearProgress />}

                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12} style={{width:window.isNativeApp&&"100%"}}>
                <label for="my-input">Agência</label>
                <InputMask
                  mask={'9999'}
                  disabled={false}
                  placeholder="Agência sem digitos"
                  value={agency}
                  fullWidth
                  maskChar={"_"}
                  my={2}
                  reverse={true}
                  onChange={e => setAgency(String(e.target.value))}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="agency"
                  type="text"
                  id="agency"
                  autoComplete="agency"
                  keyboardType="numeric"
                  placeholder="Agência sem digitos"
                />}
                </InputMask>
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12} style={{width:window.isNativeApp&&"100%"}}>
                <label for="my-input">Numero da conta</label>
     
                <TextField   required
                  fullWidth
                  name="contacomdigito_cad_transferencia"
                  type="text"
                  id="contacomdigito_cad_transferencia"
                  autoComplete="contacomdigito_cad_transferencia"
                  placeholder="Numero da conta"
                />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12} style={{width:window.isNativeApp&&"100%"}}>
                <FormControl fullWidth >
                  <InputLabel id="tipo-conta-add-payeer-label">Tipo de Conta</InputLabel>
                    <Select fullWidth required
                      labelId="tipo-conta-add-payeer-label"
                      id="tipo-conta-add-payeer"
                      label="Tipo de Conta"
                      onChange={e=> setAccountType(e.target.value)}
                      value={accountType} >
                      <MenuItem value={1}>Conta corrente</MenuItem>
                      <MenuItem value={2}>Conta Poupança</MenuItem>
                      <MenuItem value={3}>Conta Pagamento</MenuItem>
                    </Select>
                  </FormControl>

                  </Grid>
              </Grid>
      


            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              <br></br>
              {window.isNativeApp &&
                  <center> <Button variant="contained" disabled={saving} color="primary" mt={3} onClick={handleSubmit} >
                  Salvar informações
                </Button></center>
              }
               {!window.isNativeApp &&
                 <Button variant="contained" disabled={saving} color="primary" mt={3} onClick={handleSubmit} >
                  Salvar informações
                </Button>
              }
         
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                




    </Dialog>
  );
}


export default AddNewClient;
