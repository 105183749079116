import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import {QuestionMark} from '@mui/icons-material';

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { TableRows } from "@mui/icons-material";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);





function Profile() {

  let { getApi,postApi } = useAuth();

const [businessDetails, setbusinessDetails] = useState([]);
const [businessDetailsNotificationSMS, setBusinessDetailsNotificationSMS] = useState([]);
const [businessDetailsNotificationEmail, setBusinessDetailsNotificationEmail] = useState([]);
const [businessDetailsNotificationVoice, setBusinessDetailsNotificationVoice] = useState([]);
const [businessDetailsNotificationWhatsapp, setBusinessDetailsNotificationWhatsapp] = useState([]);

const [businessDetailsTransfer, setbusinessDetailsTransfer] = useState([]);
const [businessDetailsBoleto, setBusinessDetailsNotificationBoleto] = useState([]);
const [businessDetailsCreditCard, setBusinessDetailsNotificationCreditCard] = useState([]);
const [businessDetailsPix, setBusinessDetailsNotificationPix] = useState([]);
const [loading, setLoading] = useState(false);

const Typography = styled(MuiTypography)(spacing);


const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

useEffect(() => {
  LoadDetailsAccount();
},[]);


const LoadDetailsAccount = async () => {
    
  setLoading(true);
  let response = await getApi("/business-account/details");
  setbusinessDetails(response);
  setBusinessDetailsNotificationSMS(response.fees_notification_sms);
  setBusinessDetailsNotificationEmail(response.fees_notification_email);
  setBusinessDetailsNotificationVoice(response.fees_notification_voice);
  setBusinessDetailsNotificationWhatsapp(response.fees_notification_whatsapp);
  setbusinessDetailsTransfer(response.fees_transfer)
  setBusinessDetailsNotificationBoleto(response.fees_boletos)
  setBusinessDetailsNotificationCreditCard(response.fees_creditcard)
  setBusinessDetailsNotificationPix(response.fees_pix)
  setLoading(false);

};



  return (
    <React.Fragment>
      <Helmet title="Detalhes da Conta" />

      <Typography variant="h3" gutterBottom display="inline">
        Detalhes da Conta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
        Detalhes da Conta
        </Link>
        <Typography>Exibição</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading &&
         
         <Grid container justifyContent="center" >
         <Grid item>
         <Box sx={{ display: 'flex' }}>
            <CircularProgress 
        alignItems="center"
        justifyContent="center" color="secondary" />
         </Box>
         </Grid>
       </Grid>


       }
    {!loading &&
    businessDetailsTransfer.table &&
    
 

      <Grid sx={{justifyContent:'center', marginTop:5}} container >
          
      <TableContainer component={Paper} sx={{ width:'800px'}}>
        <Table  size="medium" aria-label="a dense table">
        <TableBody>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Recebimento Boleto com Pix</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
            {
                    businessDetailsBoleto &&
                    businessDetailsBoleto.table.filter((x) => x.price==0 ).map((el, i) => (
                    <React.Fragment>
                    
                    {
                     (el.from==0 && el.up_to>200 &&  "Compensação gratuita e sem limites" ) ||

                    (el.from==0 && el.up_to<200 &&  "Até "+el.up_to+" compensações de graça ")  ||

                    (el.from>0 && el.up_to>200 &&  "De "+el.from+" até "+el.up_to+" compensações de graça ") ||
                    
                    (el.from>0 && el.up_to<200 &&  "De "+el.from+" até "+el.up_to+" compensações de graça ")


                    }

              {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }

               
                      <p/>
                    </React.Fragment>
                
         
          

                ))
            }


            {
                     businessDetailsBoleto &&
                    businessDetailsBoleto.table.filter((x) => x.price>0 ).map((el,i) => (
                    <React.Fragment>
                    {
                     (el.from==0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" ) ||
                     
                     (el.from==0 && el.up_to<200 &&   "Até "+el.up_to+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" ) ||
                     
                     (el.from>0 && el.up_to<200 &&   "De "+el.from+" Até "+el.up_to+" por apenas "+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" )   ||
                     
                     (el.from>0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" após a "+el.from+"º compensação no mês" ) 

                   
                    } 
                    {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }
                   

                  <p></p>
                    </React.Fragment>
                
         
          
              
                ))
            }



      
            </TableCell>
          </TableRow>

          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Recebimento de Pix</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
            {
                    businessDetailsPix &&
                    businessDetailsPix.table.filter((x) => x.price==0 ).map((el, i) => (
                    <React.Fragment>
                    
                    {
                     (el.from==0 && el.up_to>200 &&  "Compensação gratuita e sem limites" ) ||

                    (el.from==0 && el.up_to<200 &&  "Até "+el.up_to+" compensações de graça ")  ||

                    (el.from>0 && el.up_to>200 &&  "De "+el.from+" até "+el.up_to+" compensações de graça ") ||
                    
                    (el.from>0 && el.up_to<200 &&  "De "+el.from+" até "+el.up_to+" compensações de graça ")


                    }

                {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }

                  
               
                      <p/>
                    </React.Fragment>
                
         
          

                ))
            }


            {
                    
                    businessDetailsPix &&
                    businessDetailsPix.table.filter((x) => x.price>0 ).map((el,i) => (
                    <React.Fragment>
                    {
                     (el.from==0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" ) ||
                     
                     (el.from==0 && el.up_to<200 &&   "Até "+el.up_to+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" ) ||
                     
                     (el.from>0 && el.up_to<200 &&   "De "+el.from+" Até "+el.up_to+" por apenas "+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por compensação" )   ||
                     
                     (el.from>0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" após a "+el.from+"º compensação no mês" ) 

                    } 
                    {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }


                  {i==businessDetailsPix.table.filter((x) =>x.price>0 ).length && <p></p>}
                 
                    </React.Fragment>
                
             
          

                ))
            }


      
            </TableCell>
          </TableRow>

          {businessDetailsCreditCard.table &&
          
          
          
        
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Recebimento via Cartão</strong> 
            </TableCell>
            
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <React.Fragment>
                            <Table sx={{  alignItems:"center", alignContent:"flex-end", justifyContent:"flex-end" }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Parcela</TableCell>
                                  <TableCell align="center">Tarifa</TableCell>
                                  <TableCell align="center">
                                  
                                    Repasse ao cliente
                                    <Tooltip title="Taxas que serão repassadas ao seu cliente.">
                                    <IconButton size="small">
                                        <QuestionMark />
                                      </IconButton>
                                    </Tooltip>
                                    </TableCell>
                                  <TableCell align="center">Prazo</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessDetailsCreditCard.table.map((row) => (
                                  <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row" align="center">
                                      {row.installment}x
                                    </TableCell>
                                    <TableCell align="center">{row.price_percentage+"% + "+parseFloat(row.price_fixed).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
                                    <TableCell align="center">
                                      { 
                                        row.price_costumer_percentage>0?
                                          row.price_costumer_percentage+"%"
                                          :
                                          '-'
                                      }
                                        {
                                        row.price_costumer_fixed>0 ?
                                         " + "+parseFloat(row.price_costumer_fixed).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                         :
                                         '-'
                                        }

                                      
                                   </TableCell>
                                   <TableCell align="center">{row.release_interval_days} dias</TableCell>

                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>

                    </React.Fragment>
                    </TableCell>
                
         
          

           





      
          </TableRow>
  }


          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Transferência Pix</strong> 
            </TableCell>
           
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
             
            {
                    businessDetailsTransfer &&
                    businessDetailsTransfer.table.filter((x) => x.type==2 && x.price==0 ).map((el, i) => (
                    <React.Fragment>
                    
                    {
                     (el.from==0 && el.up_to>200 &&  "Transferências gratuitas e sem limites" ) ||

                    (el.from==0 && el.up_to<200 &&  "Até "+el.up_to+" transferências de graça ")  ||

                    (el.from>0 && el.up_to>200 &&  "De "+el.from+" até "+el.up_to+" transferências de graça ") ||
                    
                    (el.from>0 && el.up_to<200 &&  "De "+el.from+" até "+el.up_to+" transferências de graça ")


                    }


                  
                  <strong><Typography fontSize={10}>{el.shared&&"(limite compartilhado)"}</Typography></strong>
                      <p/>
                    </React.Fragment>
                
         
          

                ))
            }


            {
                    businessDetailsTransfer &&
                    businessDetailsTransfer.table.filter((x) => x.type==2 && x.price>0 ).map((el,i) => (
                    <React.Fragment>
                    {
                     (el.from==0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" ) ||
                     
                     (el.from==0 && el.up_to<200 &&   "Até "+el.up_to+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" ) ||
                     
                     (el.from>0 && el.up_to<200 &&   "De "+el.from+" Até "+el.up_to+" por apenas "+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" )   ||
                     
                     (el.from>0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" após o "+el.from+"º envio no mês" ) 


                    } 
                    {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }


                  <strong><Typography fontSize={10}>{el.shared&&"(limite compartilhado)"}</Typography>
                  </strong>
                  {i>=businessDetailsTransfer.table.filter((x) => x.type==2 && x.price>0 ).length && <p></p>}
                      
                    </React.Fragment>
                
         
          

                ))
            }

      
          
            </TableCell>
          </TableRow>


          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Transferência TED</strong> 
            </TableCell>
           
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
              
           
             {
                    businessDetailsTransfer &&
                    businessDetailsTransfer.table.map(el => (
                      el.type==1 &&
                      el.price==0 &&
                    <React.Fragment>
                    
                    {
                     
                      (el.from==0 && el.up_to>200 &&  "Transferências gratuitas e sem limites" ) ||
 
                     (el.from==0 && el.up_to<200 &&  "Até "+el.up_to+" transferências de graça no mês ")  ||
 
                     (el.from>0 && el.up_to>200 &&  "De "+el.from+" até "+el.up_to+" transferências de graça ") ||
                     
                     (el.from>0 && el.up_to<200 &&  "De "+el.from+" até "+el.up_to+" transferências de graça ")
 

                    }


                  
                  <strong><Typography fontSize={10}>{el.shared?"(limite compartilhado)":null}</Typography></strong>
                      <p/>
                    </React.Fragment>
                
         
          

                ))
            }


            {
                    businessDetailsTransfer.table.filter((x) => x.type==1 && x.price>0 ).map((el,i) => (
                    <React.Fragment>
                    {
                     (el.from==0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" ) ||
                     
                     (el.from==0 && el.up_to<200 &&   "Até "+el.up_to+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" ) ||
                     
                     (el.from>0 && el.up_to<200 &&   "De "+el.from+" Até "+el.up_to+" por apenas "+parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+ " por transferência" )   ||
                     
                     (el.from>0 && el.up_to>200 &&   parseFloat(el.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" após o "+el.from+"º envio no mês" ) 


                    } 
                    {el.validity &&  <small> - Vigência até { new Date(el.validity).toLocaleDateString('pt-br')}</small> }


                  <strong><Typography fontSize={10}>{el.shared?"(limite compartilhado)":null}</Typography>
                  </strong>
                  {i>=businessDetailsTransfer.table.filter((x) => x.type==1 && x.price>0 ).length && <p></p>}
                      
                    </React.Fragment>

                ))
            }

                
          
            </TableCell>
          </TableRow>

          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Envio de E-mail</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
              {businessDetailsNotificationEmail.price==0?
              "Mensagens via e-mail grátis" : 
           ( parseFloat(businessDetailsNotificationEmail.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" cada ( Você já enviou "+businessDetailsNotificationEmail.usage_total+" emails)	"+
           <br></br> )}
              {
                ( businessDetailsNotificationEmail.qtd_free>0 && businessDetailsNotificationEmail.qtd_free<1000000 )
                &&"Você possui "+businessDetailsNotificationEmail.qtd_free+" grátis"
              }
            </TableCell>
          </TableRow>


          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell  align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Envio de SMS</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
            {
            businessDetailsNotificationSMS.price_after_free_onetime?
            parseFloat(businessDetailsNotificationSMS.price_after_free_onetime).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            :
            parseFloat(businessDetailsNotificationSMS.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          } cada ( Você já enviou {businessDetailsNotificationSMS.usage_total} SMS)	<br></br>
              {businessDetailsNotificationSMS.qtd_free>0&&"Você possui "+businessDetailsNotificationSMS.qtd_free+" grátis"}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Mensagem de WhatsApp</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
            {
            businessDetailsNotificationWhatsapp.price_after_free_onetime?
            parseFloat(businessDetailsNotificationWhatsapp.price_after_free_onetime).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            :
            parseFloat(businessDetailsNotificationWhatsapp.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          } cada ( Você já enviou {businessDetailsNotificationWhatsapp.usage_total} WhatsApp)	<br></br>
              {businessDetailsNotificationWhatsapp.qtd_free>0&&"Você possui "+businessDetailsNotificationWhatsapp.qtd_free+" grátis"}
            </TableCell>
          </TableRow>


          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell align="center" component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
              <strong>Envio de Robô de Voz</strong> 
            </TableCell>
            <TableCell align="center" sx={{fontSize:14, fontFamily:FontFamily}}>
            {
            businessDetailsNotificationVoice.price_after_free_onetime?
            parseFloat(businessDetailsNotificationVoice.price_after_free_onetime).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            :
            parseFloat(businessDetailsNotificationVoice.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          } cada ( Você já enviou {businessDetailsNotificationVoice.usage_total} Ligações)	<br></br>
              {businessDetailsNotificationVoice.qtd_free>0&&"Você possui "+businessDetailsNotificationVoice.qtd_free+" grátis"}
            </TableCell>
          </TableRow>


        </TableBody>
      </Table>
    </TableContainer>

      </Grid>   
      }
    </React.Fragment>
  );
}

export default Profile;
