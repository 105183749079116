import * as React from 'react';
import { Button, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography,
  Paper as MuiPaper,
  MenuItem,
  Select,
  CircularProgress,
  Checkbox, } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Link from '@mui/material/Link';
import { NavLink, useNavigate } from "react-router-dom";
import { Box, spacing } from '@mui/system';
import styled from "@emotion/styled";
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {ptBR} from 'date-fns/locale'
import { useEffect } from 'react';

export default function ConvertToSignature({invoice_group, open,setOpen,invoices}) {
    const navigate = useNavigate();

let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
  
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const style = {
  position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.isNativeApp?"90%":600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    overflowY: 'scroll',
    height:"75%",
    p: 4,
};

const fontSizeTexts=window.isNativeApp?10:13;
const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const Paper = styled(MuiPaper)(spacing);

let [installments, setInstallments] = useState(-1);
let [cycletype, setCycleType] = useState(1);
let [next_due_date, setNextDueDate] = useState('');
let [saving, setSaving] = useState(false);
let [errorMsg, setErrorMsg] = useState(null);

let [importInvoices, setImportInvoices] = useState(true);

const HandleSubmit = async () => {
  setSaving(true);

  let response = await postApi("/invoice/converter/carne/signature/"+invoice_group.id, {
    installments:parseInt(installments),
    cycletype:parseInt(cycletype),
    next_due_date:next_due_date,
    importInvoices:Boolean(importInvoices)
  });

  setSaving(false);

  if(response.success){
    alert("O carnê foi convertido em assinatura");
    navigate("/assinaturas/show/"+response.signature.id);
  }
  else if(response.error){
    let errorMsgTmp=response.error;
      alert(errorMsgTmp.toString())
    
  }else{
    if(response.installments){
      alert(response.installments.toString())
    }else if(response.cycletype){
      alert(response.cycletype.toString())
    }else if(response.next_due_date){
      alert(response.next_due_date.toString())
    }else if(response.importInvoices){
      alert(response.importInvoices.toString())
    }
  }
  

}


  useEffect(() => {
    if(invoice_group){
      setNextDueDate(dayjs(
        new Date(
          new Date(invoices.slice(-1).pop().due_date).setMonth(new Date(invoices.slice(-1).pop().due_date).getMonth() + 1)
        )
      ));
      setInstallments(-1)
      setCycleType(1);

    }


  },[invoice_group]);



  return (
    <React.Fragment>
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" >
           <center> Conversão de Carnê em Assinatura</center>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} variant='caption'>
            Convertendo seu carnê em assinatura:
            <ul>
              <li>Permitirá que as próximas faturas do seu cliente seja gerada automáticamente.</li>
              <li>Suspenda, reative ou cancele a assinatura, mantendo organização.</li>
              <li>Importe as faturas do carnê para a nova assinatura e mantenha todo historico financeiro</li>
            </ul>
          </Typography>
          <small>Ultimo vencimento do carnê: 
            {
              new Date(invoices.slice(-1).pop().due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})
            }
            </small>

          <Typography sx={{ fontSize: window.isNativeApp?12:14 }} mt={1} mb={2}>
          Detalhes da nova Assinatura
        </Typography>



        <Grid container spacing={0}>

        <TableContainer component={Paper} >
       
          <Table  size="small" aria-label="a dense table">
            <TableBody>
            
              <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  > 
                  <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                  <label for="due_dayField"><strong>Ciclo da Parcela</strong> </label>
                  </TableCell>
                  <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                  <Select required
                    fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Ciclo da Parcela"
                      value={cycletype}
                      onChange={e=> setCycleType(e.target.value)}
                    >
                      <MenuItem value={1}>Mensal</MenuItem>
                          {/* <MenuItem value={2}>Semanal</MenuItem>
                          <MenuItem value={3}>Quinzenal</MenuItem> */}
                          <MenuItem value={4}>Trimensal</MenuItem>
                          <MenuItem value={5}>Semestral</MenuItem>
                          <MenuItem value={6}>Anual</MenuItem>
                    
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Qtd de Parcela</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                    <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Qtd de Parcelas"
                        onChange={e=> setInstallments(e.target.value)}
                        value={installments}
                      >
                      <MenuItem value={-1}>Indeterminado</MenuItem>
                        {
                   [...Array(72)].map((e, i) => (
                      i>1 &&
                      <MenuItem key={i} value={i}>{i} parcelas</MenuItem>
                   ))
                   }
                  </Select>
                </TableCell>
              </TableRow>

             
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <label for="next_due_dateField"><strong>Próxima Fatura</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  id="next_due_dateField"
                    label="Próxima fatura a ser gerada"
                    inputFormat="dd/MM/yyyy"
                    value={next_due_date?next_due_date:null}
                    minDate={new Date()}
                    onChange={setNextDueDate}
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
                    
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                <small>
               Importar faturas</small>
                </TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                 <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily, textAlign:"center"}}>
                <label for="next_due_dateField"><strong>Importar Faturas</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <Checkbox  defaultChecked={importInvoices} onClick={e=> setImportInvoices(!importInvoices)}/>
                </TableCell>
              </TableRow>



              </TableBody>
              </Table>
              </TableContainer>
              </Grid>
              

              <center>
      <Button sx={{marginTop:8, marginBottom:5}}
        variant="contained"
        size="medium"
        color={'boot_primary'} 
        disabled={saving}
        onClick={e=> HandleSubmit()} 
      >
        Criar nova assinatura {importInvoices==true &&" e Importar faturas"}
      </Button>
      </center>

      <Grid>
      <Button 
        variant="contained"
        size="small"
        color={'secondary'}  disabled={saving}
        onClick={e=> handleClose()}
      >
        Fechar
      </Button>
      </Grid>
      <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errorMsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 



        </Box>
      </Modal>

</React.Fragment>
  );
}