import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ShowInadimplenteClient from "../edit/index"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,getApi2,postApi,postApi2 } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);




const [includeExpiredInvoices, setIncludeExpiredInvoices] = useState(false);


const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);


const [arrayResult, setArrayResult] = React.useState([]);


const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [
  {
    field: "fullname",
    headerName: "Nome Completo",
    sortable: false,
    width: 250,
    editable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.fullname}</div>;
  }
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.tax_identifier}</div>;
    }
  },
  {
    field: "reference",
    headerName: "Referência",
    sortable: false,
    width: 180,
  },
  {
    field: "amountoverdue",
    headerName: "Valor Vencido",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return <div className="rowitem">{parseFloat(params.row.amount_overdue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
    }
  },
  {
    field: "qtd_overdue",
    headerName: "Faturas Vencidas",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.overdue}</div>;
    }
  },

  {
    field: "1º  Fat. Vencida",
    renderHeader: (params: GridColumnHeaderParams) => (
      <React.Fragment>
        {filterType==1?"1º  Fat. Vencida":"Dias vencidos"}
      </React.Fragment>
    ),
    sortable: false,
    width: 150,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
      
          {filterType==1 && params.row.first_invoice_overdue}
          {
          filterType==2 &&
          
          params.row.diff_first_invoice_overdue+" dias"
          }

      </React.Fragment>
    
    ),
  },


   {
    field: "qtd_expired",
    headerName: "Faturas Expiradas",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.expired}</div>;
    }
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={"error"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e=> handleClickOpenEditClient(params.row)}>
          Ver Cobranças
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState([0]);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("fullname");
const [query_filterDateType, setQuery_filterDateType] = useState(3);

let [query_filterDateStart, setQuery_filterDateStart] = useState(null);
let yesterdayDate=Date.now() - 1*27*3600*1000;
let [query_filterDateEnd, setQuery_filterDateEnd] = useState(new Date(yesterdayDate).toISOString().slice(0,10));

let [filterType, setFilterType] = useState(1);
let [condtitionFilterQtdOverDue, setCondtitionFilterQtdOverDue] = useState(1);
let [qtdFilterQtdOverDue, setQtdFilterQtdOverDue] = useState(1);

function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={
    "querycomplete":true
  };
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  if(includeExpiredInvoices==true){
    search_Status.push(10)
  }

  invoiceTmp["queryString"]={
    "invoice":{ 
      "status":search_Status
    },
  };



  if(filterType==1){
    // Filtro de Data
    invoiceTmp["queryString"]["invoice"]["filterdate"]=query_filterDateType;
    invoiceTmp["queryString"]["invoice"]["filterdatevalue"]={
      "start":query_filterDateStart,
      "end": query_filterDateEnd
    };
  }else{
    invoiceTmp["queryString"]["invoice"]["filterdate"]=query_filterDateType;

    if(condtitionFilterQtdOverDue==1){
      var d1 = new Date();
      var d2 = new Date();

      query_filterDateStart=null;
      query_filterDateEnd=new Date(d2.setDate(d2.getDate() - 1 - qtdFilterQtdOverDue)).toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
    }

    if(condtitionFilterQtdOverDue==2){
      var d1 = new Date();
      var d2 = new Date();

      query_filterDateStart=new Date(d1.setDate(d1.getDate() - qtdFilterQtdOverDue*2)).toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});;
      query_filterDateEnd=new Date(d2.setDate(d2.getDate() - qtdFilterQtdOverDue)).toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
    }
    if(condtitionFilterQtdOverDue==3){
      var d1 = new Date();
      var d2 = new Date();

      query_filterDateStart=d1.setDate(d1.getDate() - qtdFilterQtdOverDue);
      query_filterDateEnd=d2.setDate(d2.getDate() - qtdFilterQtdOverDue);
    }
    
    invoiceTmp["queryString"]["invoice"]["filterdatevalue"]={
      "start":query_filterDateStart?new Date(query_filterDateStart).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null,
      "end": query_filterDateEnd?new Date(query_filterDateEnd).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null
    };
  }
  

   // Filtro de usuario
   if([
      "fullname", 
      "tax_identifier", 
      "email", 
      "phone",
      "street",
      "district",
      "city",
      "state",
      "zipcode"
    ].includes(query_type)){
     
      invoiceTmp["queryString"]["costumer"]={};
      invoiceTmp["queryString"]["costumer_address"]={};
      if(query_type=="fullname"){
        invoiceTmp["queryString"]["costumer"]["fullname"]=searchText;
      }else if(query_type=="tax_identifier"){
        invoiceTmp["queryString"]["costumer"]["tax_identifier"]=searchText;
      } else if(query_type=="email"){
        invoiceTmp["queryString"]["costumer"]["email"]=searchText;
      }else if(query_type=="phone"){
        invoiceTmp["queryString"]["costumer"]["phone"]=searchText;
      }else if(query_type=="reference"){
        invoiceTmp["queryString"]["costumer"]["reference"]=searchText;
      }else if(query_type=="id"){
        invoiceTmp["queryString"]["costumer"]["id"]=searchText;
      }else if(query_type=="street"){
        invoiceTmp["queryString"]["costumer_address"]["street"]=searchText;
      }else if(query_type=="district"){
        invoiceTmp["queryString"]["costumer_address"]["district"]=searchText;
      }else if(query_type=="city"){
        invoiceTmp["queryString"]["costumer_address"]["city"]=searchText;
      }else if(query_type=="state"){
        invoiceTmp["queryString"]["costumer_address"]["state"]=searchText;
      }else if(query_type=="zipcode"){
        invoiceTmp["queryString"]["costumer_address"]["zipcode"]=searchText;
      }
   }

   


  return invoiceTmp;

}

useEffect(() => {

  LoadStatusList();
  LoadMyClients();
},[]);

const LoadStatusList = async (page_arg=null, limit_arg=null) => {
    
  let response = await getApi2("/invoice/status/list",{ });
  setStatusList(response)
};

const ExportPDF = async (page_arg=null, limit_arg=null) => {
    
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/report/costumer/overdue", Arrayp);
  
  if(response){
    alert("Seu relatório está sendo processado.\nEstaremos enviando dentro de poucos segundos.")
  }

};

const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    

  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi2("/costumer/list/querycomplete", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista.map(x=> x.costumer));
  setLoadingList(false);

};


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
      <Grid container spacing={6}>
                <Grid item md={6}
                sx={{width:window.isNativeApp&&"100%"}}
              >
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}
                
                sx={{width:window.isNativeApp&&"100%"}}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
                          <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
                          <MenuItem value={"id"}>Código do Cliente</MenuItem>
                          <MenuItem value={"reference"}>Referência do cliente</MenuItem>
                          <MenuItem value={"email"}>E-mail</MenuItem>
                          <MenuItem value={"phone"}>Telefone</MenuItem>
                          <MenuItem value={"zipcode"}>Endereço - CEP</MenuItem>
                          <MenuItem value={"street"}>Endereço - Logradouro</MenuItem>
                          <MenuItem value={"district"}>Endereço - Bairro</MenuItem>
                          <MenuItem value={"city"}>Endereço - Cidade</MenuItem>
                          <MenuItem value={"state"}>Endereço - Estado</MenuItem>
                        
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>

              <Grid container spacing={6} marginBottom={5}>
              <Grid item md={2}>
              <FormControlLabel
                  value={true}
                  checked={includeExpiredInvoices}
                  onClick={e=> setIncludeExpiredInvoices(!includeExpiredInvoices)}
                  control={<Switch color="primary" />}
                  label="Incluir Faturas expiradas"
                  labelPlacement="Incluir Faturas expiradas"
                />
                </Grid>

                <Grid item md={2} 
                    sx={{width:window.isNativeApp&&"50%"}}
                  >
                     <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Filtrar por:</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={filterType}
                        onChange={e => setFilterType(e.target.value)}>
                          <MenuItem value={1}>Data de Vencimento</MenuItem>
                          <MenuItem value={2}>Quantidade de dias vencidos</MenuItem>
                      </Select>

                      
                      </FormControl>
                </Grid>


                {filterType==2 && 
                  <React.Fragment>
                     <Grid item md={2} 
                      sx={{width:window.isNativeApp&&"50%"}}
                    >
                     <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Condição:</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={condtitionFilterQtdOverDue}
                        onChange={e => setCondtitionFilterQtdOverDue(e.target.value)}>
                          <MenuItem value={1}>Maior ou igual a</MenuItem>
                          <MenuItem value={2}>Menor ou igual a</MenuItem>
                          <MenuItem value={3}>Igual</MenuItem>
                      </Select>

                      
                      </FormControl>
                  </Grid>
                  <Grid item md={2} 
                    sx={{width:window.isNativeApp&&"50%"}}
                  >
                  <TextField
                    id="query"
                    label={"Quantidade de dias vencidos"}
                    variant="outlined"
                    fullWidth
                    type="text"
                    focused={true}
                    onChange={e=> setQtdFilterQtdOverDue(e.target.value)}
                    value={qtdFilterQtdOverDue}
                  />
                </Grid>
                  </React.Fragment>

                }


              {filterType==1 && 
              
              <React.Fragment>
                 <Grid item md={2} 
                    sx={{width:window.isNativeApp&&"50%"}}
                  >
                  <TextField
                    id="query"
                    label={"Data Inicial"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    InputProps={{inputProps: { max: new Date(yesterdayDate).toISOString().slice(0,10)} }}
                    onChange={e=> setQuery_filterDateStart(e.target.value)}
                    value={query_filterDateStart}
                  />
                </Grid>
                <Grid item md={2}
                sx={{width:window.isNativeApp&&"50%"}}>
                  <TextField
                  InputProps={{inputProps: { max: new Date(yesterdayDate).toISOString().slice(0,10)} }}
                    id="query"
                    label={"Data Final"}
                    variant="outlined"
                    fullWidth
                    type="date"
                  
                    focused={true}
                    onChange={e=> setQuery_filterDateEnd(e.target.value)}
                    value={query_filterDateEnd}
                  />
                </Grid>
              </React.Fragment>
              
              }

               
              </Grid>
              <Button variant="contained"  color="primary" mt={4} onClick={e => LoadMyClients(0)}
             >
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}  
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
          
          <ShowInadimplenteClient
            selectedValue={selectedEditValue}
            open={openEditClient}
            onClose={handleCloseEditClient}
          />
    
        </div>
      </Paper>
      
    <Grid container justifyContent="right" >
      <Button onClick={()=> ExportPDF()} color={"error"} variant="outlined">Exportar PDF</Button>
    </Grid>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Lista de Carnes" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Clientes Inadimplentes
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
        Clientes 
        </Link>
        <Typography>Inadimplentes</Typography>
      </Breadcrumbs>
      </Grid>
     
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />


       
       
    </React.Fragment>
  );
}

export default DataGridPage;
