import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import UpdateDueDate from "../../../Cobrancas/update-due"
import UpdateStatusCobMarkedPaid from "../../../Cobrancas/update-marked-as-paid"

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  CardContent,
  Link,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import { spacing, Stack } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import DataGridImprovedCobList from "./DataGridImprovedCobList";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;







function InvoicesSignaturesComponent({key,signatureM}) {
  
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);

const [costumer, setCostumer] = React.useState(signatureM.costumer);


  let { getApi,postApi } = useAuth();

const [blockAction, setBlockAction] = useState(false);
const [loading, setLoading] = useState(true);


const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(25);
const [totalRecords, setTotalRecords] = useState(0);

const [openUpdateDueDate , setOpenUpdateDueDate ] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);
const [openUpdateStatusMarkedPaid, setOpenUpdateStatusMarkedPaid] = useState(false);

const navigate = useNavigate();

const handleClickOpenUpdateDueDate = (selectedEditValue) => {
  setOpenUpdateDueDate(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseUpdateDueDate = (value) => {
  setOpenUpdateDueDate(false);
  setSelectedEditValue(value);
};


const HandleOpenUpdateMarkedPaid = (selectedEditValue) => {
  console.log(selectedEditValue);
  setOpenUpdateStatusMarkedPaid(true);
  setSelectedEditValue(selectedEditValue);
};
const handleCloseInvoiceUpdateMarkedPaid = (value) => {
  setOpenUpdateStatusMarkedPaid(false);
  setSelectedEditValue(value);
};



const ChangeStatus = async (status,invoice) => {
  if (!window.confirm("Você realmente deseja fazer isso?")) {
    return ;
  }
  setBlockAction(true);
  let response = await postApi("/invoice/marcar/"+status+"/"+invoice.id,{});
  if(response.error){
    alert(response.error.toString());
  }else if(response.message){
    if(response.message=='This action is unauthorized.'){
      alert("Você não tem autorização");
      setBlockAction(false)

      return ;
    }
  }else{
    let cob=invoice;
    if(status=="pago"){
      invoice["status"]["id"]=7;
      invoice["status"]["name"]="Marcado como Pago";
      invoice["status"]["extended_name"]="Marcado como Pago";
   
    }else  if(status=="cancelado"){
      invoice["status"]["id"]=3;
      invoice["status"]["name"]="Cancelada";
      invoice["status"]["extended_name"]="Transação Cancelada";
     

    }
  }
  setBlockAction(false)
};


const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [

  {
    field: "reference",
    headerName: "Referência Pedido",
    sortable: false,
    width: 200,
  },
  {
    field: "due_date",
    headerName: "Vencimento",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      let DateC=new Date(params.row.due_date);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        }</div>;
    }
  },
  {
    field: "payment_date",
    headerName: "Dt. Pagamento",
    sortable: false,
    width: 120
  },
  {
    field: "amount_paid",
    headerName: "Valor Pago",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      let amount= 
        ( params.row.status["id"]!=7?params.row.amount_paid:
        params.row.amount_marked_paid)??null ;

      return <div className="rowitem"> {
        amount>0?parseFloat(amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):null 
        }</div>;
    }
  },
  {
    field: "amount",
    headerName: "Valor Total",
    sortable: false,
    width: 120
  },
  
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 150,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={params.row.status?ColorButtonTransaction(params.row.status["id"]):"primary"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e => navigate("/cobrancas/show/"+params.row.id)}>
          {params.row.status?params.row.status["name"]:null}
        </Button>
      </React.Fragment>
    
        
    ),
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 400,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
          <Link
            target="_blank"
            href={params.row.checkout_link}>
            <Button
                variant="contained"
                size="small"
                color={"primary"}>
              Checkout
            </Button>
          </Link>

        <Button variant='outlined' color="primary" size={"small"} disabled={(blockAction && ![0,3,10].includes(params.row.status["id"])) || blockAction} onClick={e=> HandleOpenUpdateMarkedPaid(params.row)}>
                    Marcar como pago
                </Button>
                
                <Button variant='outlined' color="secondary" onClick={e=> handleClickOpenUpdateDueDate(params.row)} size={"small"} disabled={(!blockAction && params.row.status["id"]!=0 ) || blockAction  } >
                    Atualizar
                </Button>
                <Button variant='outlined' color="boot_danger" size={"small"} disabled={(blockAction && ![0,7,10].includes(params.row.status["id"]))
            || blockAction}   onClick={e=> ChangeStatus("cancelado", params.row)}>
                    Cancelar
                </Button>
                
      </React.Fragment>
    
        
    ),
  },
];



const LoadQueryParams= (limit_arg,page_arg) => {

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["orderBy"]="due_date";
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "invoice":{
      "status":-1
    },
    "costumer":{
      "id":costumer.id
    },
    "signature":{
      "id":signatureM.id
    }
  };

  invoiceTmp["queryString"]["invoice"]["archived"]=includeArchivedInvoice;



  return invoiceTmp;

}

const FillArrayResult=(data)=>{
  let arrayTMP=[];
  data.forEach(function(curr, index, array){
    arrayTMP.push({
      amount:parseFloat(curr["amount"]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) ,
      status:curr["status"],
      statusId:curr["status"]["id"],
      fullname:curr["costumer"]["fullname"],
      tax_identifier:curr["costumer"]["tax_identifier"],
      id:curr["id"],
      reference:curr["reference"],
      due_date:curr["due_date"],
      created_at:curr["created_at"],
      amount_paid:curr["amount_paid"],
      amount_marked_paid:curr["amount_marked_paid"],
      checkout_link:curr["checkout_link"],
      payment_date:curr.payment_date?new Date(curr["payment_date"]).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}):null
    })
    setListCobr(arrayTMP)
  });

}
const [includeArchivedInvoice, setIncludeArchiveddInvoice] = useState(false);



const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    
  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/invoice/list", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  FillArrayResult(response.lista.map(x=> x.invoice));
  setLoadingList(false);

};



useEffect(() => {
  
    if(costumer.id){
      LoadMyClients();
    }
},[key,costumer,includeArchivedInvoice]);



  return (
    <React.Fragment>
       <Grid item md={3}>
       <Tooltip title="Inclui na listagem todos os boletos, incluindo os arquivados."> 
       <FormControlLabel
                      value={true}
                      checked={includeArchivedInvoice}
                      onClick={e=> setIncludeArchiveddInvoice(!includeArchivedInvoice)}
                      control={<Switch color="primary" />}
                      label="Incluir cobranças arquivados"
                      labelPlacement="Incluir cobranças arquivados"
                    />
        </Tooltip>
                </Grid>
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Cobranças da Assinatura
        </Typography>
     
        <DataGridImprovedCobList key={loadingList}
         rowsPerPageOptions={[5, 10, 25, 50,100]}
         rows={listCobr}
         columns={columns}
         pageSize={window.isNativeApp?50:limitPage}
         rowCount={totalRecords}
         page={page}
         onPageSizeChange={e  => {LoadMyClients(null, e)  } }
         paginationMode={'server'}
         onPageChange={e  => {LoadMyClients(e, null)  } }
         loading={loadingList}
          handleClickOpenUpdateDueDate={handleClickOpenUpdateDueDate}
          HandleOpenUpdateMarkedPaid={HandleOpenUpdateMarkedPaid}
          blockAction={blockAction}
          ChangeStatus={ChangeStatus}
         />
          
      </CardContent>
    </Card>

    <UpdateDueDate
invoice={selectedEditValue}
open={openUpdateDueDate}
key={openUpdateDueDate}
onClose={handleCloseUpdateDueDate}
/>

<UpdateStatusCobMarkedPaid
            invoice={selectedEditValue}
            open={openUpdateStatusMarkedPaid}
            key={openUpdateStatusMarkedPaid}
            onClose={handleCloseInvoiceUpdateMarkedPaid}
          />



</React.Fragment>
  );
}


export default InvoicesSignaturesComponent;
