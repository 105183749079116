import React, { useCallback, useLayoutEffect, useState } from 'react';

import { BarcodeReader, BoletoBarcodeReader, BoletoBarcodeReaderProvider, BoletoBarcodeReaderSupport } from 'react-boleto-reader';
import 'react-boleto-reader/dist/index.css'; /** The default styles. It's optional. */

import './index.css'; /** Not important: example styles. */

export default function Scanner({setScanning,scanning,setBarCode}) {
  /** Not important: example definitions */
  const tabs = [ 'boleto', 'barcode' ];
  const tabsId = 'example_tabs';
  const headerId = 'example_header';
  const [tab, setTab] = useState('boleto');
  const [boleto, setBoleto] = useState(null);
  const [barcode, setBarcode] = useState(null);

  const reading=scanning;
  const setReading=setScanning;
  const [readersHeight, setReadersHeight] = useState(0);
  const [readersWidth, setReadersWidth] = useState(0);


  let isSmartDevice =true;
  /**
   * Not important: example stuff
   */
  const reset = useCallback(() => {
    setBoleto(null);
    setBarcode(null);
    setReading(false);
  }, []);


  /**
   *
   */
  const onCancel = useCallback(() => {
    reset();
  }, [reset]);

  /**
   * Important:
   *
   * This is the callback defined to receive boleto data
   * when the reader have sucesse identifying that big barcode.
   *
   * In this example we have the `reading` variable,
   * who helps to render or not render the BoletoBarcodeReader component.
   *
   * You don't need to do like this.
   *
   * @param {Object} detectedBoleto
   */
  const onDetectBoleto = useCallback((detectedBoleto) => {
    console.log(detectedBoleto);
    setReading(false);

    const {
      barcode,
      barcodeTyped,
      banks,
      expired,
      expiredDays,
      expirationDate,
      prettyAmount,
      type,
    } = detectedBoleto;
   
    if(barcode){
      setBarCode(barcodeTyped);
      setReading(false);
      setBoleto(detectedBoleto);
      setScanning(false);
    }
   
  }, []);


  /**
   * Not important: example stuff
   */
  const getElementHeight = useCallback((elementId = '') => {
    const element = document.getElementById(elementId);

    return !element || !element.offsetHeight ? 0 : element.offsetHeight;
  }, []);

  /**
   * Not important: example stuff
   */
  useLayoutEffect(() => {
    function calcReaderHeight() {
      const tabsHeight = getElementHeight(tabsId);
      const headerHeight = getElementHeight(headerId);


      setReadersHeight(`${window.innerHeight - (tabsHeight + headerHeight)}px`);
      setReadersWidth(`${window.innerWidth - (tabsHeight + headerHeight)}px`);
    }

    calcReaderHeight();

    window.addEventListener('resize', calcReaderHeight);

    return () => {
      window.removeEventListener('resize', calcReaderHeight);
    }
  }, [getElementHeight]);

  return (
    <main>
     
      <section>
        <BoletoBarcodeReaderProvider>
          <BoletoBarcodeReaderSupport >
                  <div id="readers">
                    {tab === 'boleto' && (
                      <div id={`${tabsId}_reader_boleto`}>
                        <BoletoBarcodeReader
                        minWidth={readersHeight}
                        
                        height={readersWidth}
                          onCancel={onCancel}
                          onDetected={onDetectBoleto}
                        />
                      </div>
                    )}
                   
                  </div>
         
          </BoletoBarcodeReaderSupport>
        </BoletoBarcodeReaderProvider>
      </section>
    </main>
  )
}