import React, { Component, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { FormGroup, Paper, Typography } from "@mui/material";
import { Navigate, Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {ptBR} from 'date-fns/locale'

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment,IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { FormControl, FormControlLabel, FormLabel, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom'
import { setBusinessAccount } from "../../utils/jwt";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";


function PageSignUpStep2() {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,checkAuthBS } = useAuth();
const navigate = useNavigate();
let location=useLocation();

let [saving, setSalving] = useState(false);
let [errormsg, setErrorMsg] = useState('');

let [fullname_accountable, setFullnameAccountable] = useState('');
let [identifier_accountable, setIdentifierAccountable] = useState('');
let [identifier_birthdate, setIdentifierBirthDate] = useState('');
let [identifier, setIdentifier] = useState('');
let [company_name, setCompanyName] = useState('');
let [fantasy_name, setFantasyName] = useState('');
let [general_business_activity_id, setGeneralBusinessActivityId] = useState('');
let [general_business_account_type_id, setGeneralBusinessTypeId] = useState(1);


let [loadingCNPJData, setLoadingCnpjData] = useState(false);
let [list_businessActivity, setListBusinessActivity] = useState([]);

let [list_businessMonthlyBilling, setListBusinessMonthlyBilling] = useState([]);


let [monthly_billing, setMonthlyBilling] = useState([]);
let [is_pep, setIs_pep] = useState(false);


useEffect(() => {

  loadListBusinessAcitivty();
  loadListBusinessBusinessMonthly();

},[]);

useEffect(() => {
  
  if(user){
    setFullnameAccountable(user.fullname)
  }

},[user]);

useEffect(() => {

  if(isBSAccount){
    navigate("/sign-up/step3");
  }
},[isInitializedBS,isBSAccount]);



const loadCNPJ= async()=>{
  setErrorMsg('')
  setLoadingCnpjData(true)
  let identifierTmp=identifier.replace(/\D/g, "");
  if(identifierTmp.length!=14){
    alert("CNPJ inválido");
    setLoadingCnpjData(false)
    return false;
  }
  let response = await getApi("/consultar/cnpj/"+identifierTmp);
  setCompanyName(response.nome)
  setFantasyName(response.fantasia)
  setLoadingCnpjData(false)

  
}

const loadListBusinessAcitivty=async ()=>{
  
  let response = await getApi("/list_business_activity");
  setListBusinessActivity(response.lista);
}

const loadListBusinessBusinessMonthly=async ()=>{
  
  let response = await getApi("/list_business_monthly_billing");
  setListBusinessMonthlyBilling(response.lista);
}

const checkValues =()=>{

  if(!fullname_accountable){
    alert("Você precisa preencher o nome completo");
  }else if(!identifier_accountable){
    alert("Você precisa preencher o seu CPF");
  }else if(!identifier_birthdate){
    alert("Você precisa preencher a sua Data de Nascimento");
  }else if(!identifier && general_business_account_type_id==2){
    alert("Você precisa preencher o CNPJ");
  }else if(!company_name && general_business_account_type_id==2){
    alert("Você precisa preencher a razão social");
  }else if(!fantasy_name && general_business_account_type_id==2){
    alert("Você precisa preencher o nome fantasia");
  }else if(!general_business_activity_id){
    alert("Você precisa escolher a atividade comercial");
  }else if(!general_business_account_type_id){
    alert("Você precisa selecionar o tipo de conta que será aberta.");
  }else{
    return true;
  }
}



const handleSubmit = async (event) => {
  event.preventDefault();
  setErrorMsg(null)
  setSalving(true)
  if(!checkValues()){
    setSalving(false)
    return false;
  }


  
   
  let response = await signUpStep2("/signup/step2",{
    fullname_accountable,
    identifier_accountable,
    identifier_birthdate,
    identifier:general_business_account_type_id==1?identifier_accountable:identifier,
    company_name:general_business_account_type_id==1?fullname_accountable:company_name,
    fantasy_name:fantasy_name,
    general_business_activity_id:parseInt(general_business_activity_id),
    general_business_account_type_id:parseInt(general_business_account_type_id),
    general_business_monthly_billing_id:parseInt(monthly_billing),
    is_pep:is_pep
  });


  response_run(response);



};

const response_run=(response)=>{
  if(response.success){
    setBusinessAccount(response.general_business_account_id);

    window.location.href="/sign-up/step3";

  
  }else{

    if(response.fullname_accountable){
      setErrorMsg(response.fullname_accountable.toString())
    }else if(response.identifier_accountable){
      setErrorMsg(response.identifier_accountable.toString())
    }else if(response.identifier_birthdate){
      setErrorMsg(response.identifier_birthdate.toString())
    }else if(response.identifier){
      setErrorMsg(response.identifier.toString())
    }else if(response.company_name){
      setErrorMsg(response.company_name.toString())
    }else if(response.fantasy_name){
      setErrorMsg( response.fantasy_name.toString())
    }else if(response.general_business_activity_id){
      setErrorMsg( response.general_business_activity_id.toString())
    }
    else if(response.general_business_account_type_id){
      setErrorMsg( response.general_business_account_type_id.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSalving(false)
  }
}

  

  return (
    <React.Fragment>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
          <br></br>
          <Typography  variant="h6">
            Informações do negócio
          </Typography>
          <Typography variant="caption" gutterBottom>
            Preencha algumas informações sobre você e o seu negócio.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
              <FormControl>
                <FormLabel style={{justifyContent:'center', alignContent:'center'}} id="demo-row-radio-buttons-group-label">Tipo de Conta</FormLabel>
                <RadioGroup 
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  autoFocus
                  defaultValue={general_business_account_type_id}
                  onChange={e=> setGeneralBusinessTypeId(e.target.value)}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Pessoa Fisíca" />
                  <FormControlLabel value={2} control={<Radio />} label="Pessoa Juridica" />
                </RadioGroup>
              </FormControl>
              </Grid>
          




              <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Informações sobre você
              </Typography> 
            <br></br>            <br/>

                <TextField
                  required
                  fullWidth
                  
                  id="fullname_register2"
                  label="Nome Completo"
                  name="fullname"
                  autoComplete="fullname"
                  value={fullname_accountable}
                  onChange={e=> setFullnameAccountable(e.target.value)}
                />
                 {!user && !fullname_accountable &&
                <LinearProgress />}





              </Grid>
              <Grid item xs={6}>

              <InputMask
              mask="999.999.999-99"
              value={identifier_accountable}
              disabled={false}
              maskChar="_"
              label="Seu CPF"
              onChange={e=> setIdentifierAccountable(e.target.value)}
            >
               {() => 
                <TextField   required
                  fullWidth
                  name="cpf"
                  label="Informe o seu CPF"
                  type="text"
                  id="cpf"
                  autoComplete="cpf"
                />}
            </InputMask>
            


              </Grid>
              <Grid item xs={6}>
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                   
                    inputFormat="dd/MM/yyyy"
                    required
                    fullWidth
                    id="birthday"
                    label="Data de Nascimento"
                    name="birthday"
                    autoComplete="birthday"
                    focused={true}
                    type='date'
                    variant="outlined"
                    maxDate={new Date().setDate(new Date().getDate() - 6588)}
                    onChange={e=> setIdentifierBirthDate(e)}
                    value={identifier_birthdate}

                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                </Grid>



          {/* Se for Pessoa Juridica */}
          {general_business_account_type_id==2 &&
          <React.Fragment>
              <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Informações sobre sua empresa
              </Typography> 
              </Grid>
           
            <Grid item xs={6}>
           

            <InputMask
              mask="99.999.999/9999-99"
              value={identifier}
              disabled={false}
              maskChar="_"
              label="Seu CNPJ"
              onChange={e=> setIdentifier(e.target.value)}
            >
               {() => 
                <TextField   required
                  fullWidth
                  name="cnpj"
                  label="Informe o seu CNPJ"
                  type="text"
                  id="cnpj"
                  autoComplete="cnpj"
                />}
            </InputMask>
            
              </Grid>
              <Grid item xs={6}>
           
              <Button variant="outlined" onClick={e=> loadCNPJ()} disabled={loadingCNPJData} >Preencher</Button>
              {loadingCNPJData==true &&
                <LinearProgress />}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="corporateName"
                  placeholder="Razão Social"
                  type="text"
                  id="corporateName"
                  autoComplete="corporateName"
                  value={company_name}
                  onChange={e=> setCompanyName(e.target.value)}
                />
          
              </Grid>
            

          </React.Fragment>
          
          
          }

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="fantasyName"
                  placeholder={general_business_account_type_id==2?"Nome Fantasia":"Nome do seu Negócio"}
                  type="fantasyName"
                  id="fantasyName"
                  autoComplete="fantasyName"
                  value={fantasy_name}
                  onChange={e=> setFantasyName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="businessActivity">Atividade do seu negócio</InputLabel>
                  <Select fullWidth required
                    labelId="businessActivity"
                    id="businessActivity"
                    label="Atividade do seu negócio"
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 350 } },
                    }}
                    value={general_business_activity_id}
                    onChange={e=> setGeneralBusinessActivityId(e.target.value)}
                  >
                      {  
                        list_businessActivity.map(el => (
                          <MenuItem value={el.id} disabled={el.id==0}>{el.name}</MenuItem>
                        ))
                      }
                  </Select>
                  
                {list_businessActivity.length==0 &&
                <LinearProgress />}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Informe o seu faturamento mensal</InputLabel>
                  <Select required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Faturamento ultimos 12 meses"
                    onChange={e=> setMonthlyBilling(e.target.value)}
                  >
                   
                    {  list_businessMonthlyBilling.map(el => (
                        <MenuItem value={el.id}>{el.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel 
                onChange={e=> setIs_pep(!is_pep)} control={<Checkbox defaultChecked={is_pep==true} />} 
                label="Você é PEP (Pessoa exposta politicamente) ?" />
              </FormGroup>
              </Grid>


              

             
            </Grid>
            
            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>


            <Button
              type="submit"
              disabled={saving}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
             Continuar
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default (PageSignUpStep2);
