import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CarneEdit from "../../Carne/edit"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes({costumer}) {
let { getApi,postApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);


const [arrayResult, setArrayResult] = React.useState([]);


const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
  LoadMyClients();
};

const navigate = useNavigate();

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [
  {
    field: "created_at",
    headerName: "Criado em",
    sortable: false,
    width: 150,
    editable: false,
    renderCell: (params) => {
      let DateC=new Date(params.row.created_at);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        +" "+DateC.getHours()+":"+DateC.getMinutes()+":"+DateC.getSeconds()
        
        }</div>;
    }},
 
  {
    field: "reference",
    headerName: "Referência",
    sortable: false,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 140,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.status.name}</div>;
    }
  },
  {
    field: "amount",
    headerName: "V. Parcela",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return <div className="rowitem">{parseFloat(params.row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
    }
  },
  {
    field: "Parcelastotais",
    headerName: "Parcelas",
    sortable: false,
    width: 90,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments==-1?"Indeterminado":params.row.installments}</div>;
    }
  },
  {
    field: "ParcelasPendente",
    headerName: "Em aberto",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_pending}</div>;
    }
  },
  {
    field: "ParcelasAtrasadas",
    headerName: "Atrasadas",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_overdue}</div>;
    }
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={"boot_primary"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e => navigate("/assinaturas/show/"+params.row.id)}>
          Visualizar
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("fullname");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);
const [includeArchivedInvoice, setIncludeArchiveddInvoice] = useState(false);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "invoiceGroup":{ },
    "status":search_Status,
    "costumer" :{
      "id": costumer.id
    }
  };

  //invoiceTmp["queryString"]["invoiceGroup"]["archived"]=includeArchivedInvoice;


  return invoiceTmp;

}

useEffect(() => {

  LoadStatusList();
  LoadMyClients();
},[includeArchivedInvoice]);

const LoadStatusList = async (page_arg=null, limit_arg=null) => {
    
  let response = await getApi("/invoice/status/list",{ });
  setStatusList(response)
};



const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    

  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/invoice/signature/list", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista.map(x=> x.signature));
  setLoadingList(false);

};


  return (
    <Card mb={6}>
     
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}  
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
           {selectedEditValue.id &&
            <CarneEdit
              selectedValue={selectedEditValue}
              open={openEditClient}
              key={selectedEditValue.id}
              onClose={handleCloseEditClient}
            />
           }
    
        </div>
      </Paper>
    </Card>
  );
}

function SignatureClientComponent({costumer}) {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>

      <Divider my={6} />
  
      <DataGridClientes costumer={costumer}/>

       
       
    </React.Fragment>
  );
}

export default SignatureClientComponent;
