import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import EditClient from "../edit/index"
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  DollarSign,
} from "react-feather";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Work
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import BoletosComponent from "./Boleto/BoletoComponent";
import CarneComponent from "./CarneComponent";
import PixComponent from "./PixComponent";
import EditClientPasswordPortal from "../../Portal-do-Cliente/clientes-associados/edit"
import AttachmentsCostumerBusiness from "./AttachmentsCostumerBusiness";
import ListCustomFieldComponent from "../../CustomField/ListCustomFieldComponent";
import MenuCreateBillComponent from "../../components/menuCreateBillComponent";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);



const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;


function About({costumer}) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {costumer.fullname} -   {costumer.tax_identifier}
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Referência:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumer.reference?costumer.reference:"Não definida"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              E-mail:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumer.email?costumer.email:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Telefone:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumer.phone?costumer.phone:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function AddressComponent({costumer, costumerAddress}) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Endereço do cliente
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              CEP:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.zipcode?costumerAddress.zipcode:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Logradouro:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.street?costumerAddress.street:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        {
          costumerAddress.complement &&
          <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Complemento:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.complement?costumerAddress.complement:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        }
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Nº:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.number?costumerAddress.number:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Bairro:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.district?costumerAddress.district:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Cidade:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.city?costumerAddress.city:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>
              Estado:
            </Typography>
          </Grid>
          <Grid item sx={{marginLeft:2}}>
            <Typography variant="body1" gutterBottom>
            {costumerAddress.state?costumerAddress.state:"Não definido"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Earnings({balance}) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">
               {parseFloat(balance.paid).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Faturas Pagas 
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={
              (balance.paid/balance.total*100)>100?100:
              (balance.paid/balance.total*100)

            }
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}


function InvoicePending({balance}) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">
            {parseFloat(balance.pending).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Faturas em aberto 
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={
              (balance.pending/balance.total*100)>100?100:
              (balance.pending/balance.total*100)

            }
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function InvoiceOverDue({balance}) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">
            {parseFloat(balance.overdue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}

            </Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Faturas Vencidas 
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={
              (balance.overdue/balance.total*100)>100?100:
              (balance.overdue/balance.total*100)
            }
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}







function Profile() {
  let { getApi,postApi,deleteApi } = useAuth();

  const { id } = useParams();
  const [costumer, setCostumer] = useState({});
  const [costumerAddress, setCostumerAddress] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedEditValue, setSelectedEditValue] = useState([]);
  const [openEditClient, setOpenEditClient] = useState(false);

  const [balance, setBalance] = useState({});
  const [loadingBalance, setLoadingBalance] = useState(true);
  
  const [boxOpenned, setBoxOpenned] = useState(1);

  const handleCloseEditClient = (value) => {
    setOpenEditClient(true);
    setSelectedEditValue(value);
  };

  useEffect(() => {
    if(id){
      LoadCostumer();
      LoadBalance();
    }
    
  },[]);

  const LoadCostumer = async () => {
    
    setLoading(true);
    if(id==undefined){
      setLoading(false);
      return ;
    }else if(id==null){
      setLoading(false);
      return ;
    }else if(!id){
      setLoading(false);
      return ;
    }
    let response = await getApi("/costumer/"+id,{});
    
    setCostumer(response.costumer);
    setCostumerAddress(response.costumer_address);
    setLoading(false);
  
  };

  const LoadBalance = async () => {
    
    setLoadingBalance(true);
    let response = await getApi("/costumer/balance/"+id,{});
    
    setBalance(response);
    setLoadingBalance(false);
  
  };
  let {ShowModalFunc,showModal,isInitializedBS } = useAuth();
const [selectedValue, setSelectedValue] = React.useState([]);
const handleClose = (value) => {
  ShowModalFunc(null)
};
const handleClickOpenEditPortalClient = (selectedEditValuePortal) => {
  setOpenEditClientPortal(true);
  setSelectedEditValuePortal(selectedEditValuePortal);
};

const [selectedEditValuePortal, setSelectedEditValuePortal] = React.useState([]);
const [openEditClientPortal, setOpenEditClientPortal] = React.useState(false);
const handleCloseEditPortalClient= (value) => {
  setOpenEditClientPortal(false);
  setSelectedEditValuePortal(value);
};

const navigate = useNavigate();

const deleteUser = async (id) => {
  if (!window.confirm("Você realmente deseja fazer isso?")) {
    return ;
  }
  

  setLoading(true);
  let response = await deleteApi("/costumer/"+id,{} );
  if(response.success==true){
    alert("Cliente excluido com sucesso");
    navigate("/meus-clientes");
    
  }
  
};


  return (
    <React.Fragment>
      <EditClient
            selectedValue={selectedEditValue}
            open={openEditClient}
            onClose={handleCloseEditClient}
          />

      <Helmet title={costumer.fullname} />

      <Typography variant="h4" gutterBottom display="inline">
        Detalhes do Cliente
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/app">
          Dashboard
        </Link>
        <Link component={NavLink} to="/meus-clientes">
          Clientes
        </Link>
        <Typography>{costumer.fullname}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
   
      <Grid container spacing={0}>
  
        {loading && !costumer.id && 
            <Grid item>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Grid>
        }
         

        <Grid item xs={9} lg={9} xl={9}>
        {loadingBalance && !balance.success && 
           <Grid item>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Grid>
        }
        
       </Grid>
        <React.Fragment>    

       <Grid item xs={12} mb={4}>
        <MenuCreateBillComponent />
       </Grid>
            
        </React.Fragment>


        {balance.success &&
        
          <Grid container spacing={6} marginTop={3}>
            <Grid item xs={12} lg={4}>
              <Earnings costumer={costumer} balance={balance}/>
            </Grid>
            <Grid item xs={12} lg={4}>
              <InvoicePending costumer={costumer} balance={balance} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InvoiceOverDue costumer={costumer} balance={balance} />
            </Grid>
          </Grid>
        }

    {costumer.id &&
            <React.Fragment>
              <Grid item xs={12} lg={12} xl={12}>
        
                <React.Fragment>
                  <About costumer={costumer} costumerAddress={costumerAddress}/>
                  <AddressComponent costumer={costumer} costumerAddress={costumerAddress} />
                </React.Fragment>  

              </Grid>       
              <Grid item xs={12} lg={12} xl={12} >
              <Box p={1} display="flex" justifyContent="center" textAlign={'center'}>
                <Button 
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ marginLeft: 16 }}
                    onClick={e=> handleCloseEditClient(costumer)}>
                    Editar
                </Button>
                {
                  [1].includes(costumer.status.id) &&
                  <Button
                  variant="contained"
                  size="small"
                  color="success"
                  style={{ marginLeft: 16 }}
                  onClick={e=> handleClickOpenEditPortalClient(costumer)}>
                  Alterar Senha
                </Button>
                }
              
              <Button
                variant="contained"
                size="small"
                color="error"
                style={{ marginLeft: 16 }}
                onClick={e=>deleteUser(costumer.id)} >Excluir
              </Button>
              </Box>
            </Grid>

            {costumer &&
                 <ListCustomFieldComponent reference_identifier={costumer.id} custom_field_type={1} />
              }
            </React.Fragment>
          }


          <Grid container spacing={6} marginTop={5} >
            <Grid item xs={12} lg={12}>
          <BottomNavigation
            showLabels
            value={boxOpenned}
            onChange={(event, newValue) => {
              setBoxOpenned(newValue);
            }}
          >
            <BottomNavigationAction label="Boletos" value={1} icon={<InsertDriveFileIcon />} />
            <BottomNavigationAction label="Carnê" value={2} icon={<FileCopyIcon />} />
            <BottomNavigationAction label="Qrcode Pix" value={3} icon={<QrCodeScannerIcon />} />
            <BottomNavigationAction label="Anexos" value={4} icon={<Work />} />
          </BottomNavigation>

          {boxOpenned==1 && costumer &&
            <BoletosComponent key={"bl"+boxOpenned} costumer={costumer} />
          }
          {boxOpenned==2 && costumer &&
            <CarneComponent key={"car"+boxOpenned}  costumer={costumer} />
          }
          {boxOpenned==3 && costumer &&
            <PixComponent key={"px"+boxOpenned}  costumer={costumer} />
          }
          {boxOpenned==4 && costumer &&
            <AttachmentsCostumerBusiness key={"attach"+boxOpenned}  costumer={costumer} />
          }
</Grid>

        </Grid>
      </Grid>

      

      <EditClientPasswordPortal
            selectedValue={selectedEditValuePortal}
            open={openEditClientPortal}
            onClose={handleCloseEditPortalClient}
          />
    

    </React.Fragment>
  );
}

export default Profile;
