import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuardBS({ children }) {
  const { isAuthenticated, isInitialized, isInitializedBS, isAuthenticatedBS,businessAccount,user,
    INITIALIZEAUTHCHECK,isValidatedAuth } = useAuth();
  const location = useLocation();


  if(user){
    localStorage.setItem("@CacheBank_", JSON.stringify({email: user.email }));
  }

  if (isInitializedBS) {

    if(isValidatedAuth==false && ![ '/sign-up/step3','/sign-up/step2'   ].includes(location.pathname)  ){
      window.location.href="/app/access/check";
      return ;
    }
  
  }

  if (isInitialized && !isAuthenticated) {
    window.location.href="/sign-in";
    return ;
  }
  if (isInitializedBS && !isAuthenticatedBS) {
    window.location.href="/sign-up/step2";
    return ;
  }




 


  if(isInitialized){
    if(businessAccount.address){

      if(businessAccount.address && businessAccount.address.id && location.pathname=='/sign-up/step3'){
        window.location.href="/app";
        return ;
      }
      if(businessAccount && !businessAccount.address.id && location.pathname!='/sign-up/step3'){
        window.location.href="/sign-up/step3";
        return ;
      }
      
    }
    


    if(businessAccount && businessAccount.business_account_status_id!=null){
      if(
        businessAccount.business_account_status_id==1 && 
        (
          ![
            '/minha-conta/documentos','/minha-conta/meus-dados','/minha-conta/detalhes',
            '/meus-clientes','/cobrancas',
            '/app/extrato','/cobrancas/carnes','/meus-clientes/inadimplentes'
          
          ].includes(location.pathname) &&
          !String(location.pathname).startsWith("/meus-clientes/visualizar/")&&
          !String(location.pathname).startsWith("/cobrancas/show/")&&
          !String(location.pathname).startsWith("/transferencias")
        )
        && !String(location.pathname).startsWith("/sign-up")){
          window.location.href="/minha-conta/documentos";
          return ;
      }else if(
        businessAccount.business_account_status_id!=2 && 
        (
          ![
            '/minha-conta/documentos','/minha-conta/meus-dados','/minha-conta/detalhes',
            '/meus-clientes','/cobrancas',
            '/app/extrato','/cobrancas/carnes','/meus-clientes/inadimplentes'
          
          ].includes(location.pathname) &&
          !String(location.pathname).startsWith("/meus-clientes/visualizar/")&&
          !String(location.pathname).startsWith("/cobrancas/show/")&&
          !String(location.pathname).startsWith("/transferencias")
        )
        && !String(location.pathname).startsWith("/sign-up")){
          window.location.href="/minha-conta/documentos";
          return ;
      }
    }
    
  }


 

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuardBS;
