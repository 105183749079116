import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


const getColorByStatus= (status)=>{
  if([0,6].includes(status)){
    return "warning";
  }else if([7].includes(status)){
    return "success";
  }else{
    return "error";;
  }
}


function DashboardTable({listDocuments,listDocumentsPending}) {

  return (
    <Card mb={6}>
    <CardHeader
      action={
        <IconButton aria-label="settings" size="large">
          <MoreVertical />
        </IconButton>
      }
      title="Documentos"
    />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Dt. envio</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { listDocuments.map(elc => (
              <TableRow key={elc.id}>
                <TableCell component="th" scope="row">
                  {elc.document_type.description_obj['short']}
                </TableCell>
                <TableCell>
                  {elc.date_doc_sent &&
                    new Date(elc.date_doc_sent).toLocaleDateString('pt-br')+ " "+
                    new Date(elc.date_doc_sent).toLocaleTimeString('pt-br')
                  
                  }
                </TableCell>
                <TableCell>
                  <Chip label={elc.status.name} color={getColorByStatus(elc.status.id)} />
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      
      </TableWrapper>
      {!listDocuments.length &&
         
         <Grid container justifyContent="center" >
         <Grid item>
         <Box sx={{ display: 'flex' }}>
            <CircularProgress 
        alignItems="center"
        justifyContent="center" color="secondary" />
         </Box>
         </Grid>
       </Grid>


       }
    </Paper>
  </Card>
  )
};

export default DashboardTable;
