import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import EditClient from "../edit/index"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import DataGridImprovedCobList from "./DataGridImprovedCobList";
import MenuCreateBillComponent from "../../components/menuCreateBillComponent";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi,getApi2,postApi2 } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(10);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);
const [includeArchivedInvoice, setIncludeArchiveddInvoice] = useState(false);


const [arrayResult, setArrayResult] = React.useState([]);

const navigate = useNavigate();

const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [

  { field: "fullname", headerName: "Nome", width: "300",
  renderCell: (params: GridRenderCellParams<Date>)  => (
    <React.Fragment>
       <Link
          onClick={e => navigate("/cobrancas/show/"+params.row.id)}>
          {params.row.fullname?params.row.fullname:null}
        </Link>
    </React.Fragment>
  
      
  ) },


  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 200,
  },
  {
    field: "reference",
    headerName: "Referência Pedido",
    sortable: false,
    width: 200,
  },
  {
    field: "due_date",
    headerName: "Vencimento",
    sortable: false,
    width: 140
  },
  {
    field: "payment_date",
    headerName: "Data de Pagamento",
    sortable: false,
    width: 150
  },
  {
    field: "amount",
    headerName: "Valor Total",
    sortable: false,
    width: 130
  },
  {
    field: "amount_paid",
    headerName: "Valor Pago",
    sortable: false,
    width: 130,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Typography>
          {
            params.row.status["id"]!=7?params.row.amount_paid:
           params.row.amount_marked_paid
          }
        </Typography>
      </React.Fragment>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={params.row.status?ColorButtonTransaction(params.row.status["id"]):"primary"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e => navigate("/cobrancas/show/"+params.row.id)}>
          {params.row.status?params.row.status["name"]:null}
        </Button>
      </React.Fragment>
    ),
  },
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("fullname");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "invoice":{
      "status":search_Status
    }
  };

  // Inclusão de Arquivados
  invoiceTmp["queryString"]["invoice"]["archived"]=includeArchivedInvoice;


   // Filtro de Data
   invoiceTmp["queryString"]["invoice"]["filterdate"]=query_filterDateType;
   invoiceTmp["queryString"]["invoice"]["filterdate"]=query_filterDateType;
    invoiceTmp["queryString"]["invoice"]["filterdatevalue"]={
     "start":query_filterDateStart,
     "end": query_filterDateEnd
   };

   // Filtro de usuario
   if(["fullname","phone","tax_identifier","email","phone"].includes(query_type)){
     
    invoiceTmp["queryString"]["costumer"]={};
     if(query_type=="fullname"){
       invoiceTmp["queryString"]["costumer"]["fullname"]=searchText;
     }else if(query_type=="tax_identifier"){
       invoiceTmp["queryString"]["costumer"]["tax_identifier"]=searchText;
     } else if(query_type=="email"){
       invoiceTmp["queryString"]["costumer"]["email"]=searchText;
     }else if(query_type=="phone"){
       invoiceTmp["queryString"]["costumer"]["phone"]=searchText;
     }
     
   }
  else if(["street","district","city","state","zipcode"].includes(query_type)){
             invoiceTmp["queryString"]["costumer_address"]={};
             if(query_type=="street"){
              invoiceTmp["queryString"]["costumer_address"]["street"]=searchText;
              }else if(query_type=="district"){
                invoiceTmp["queryString"]["costumer_address"]["district"]=searchText;
              }else if(query_type=="city"){
                invoiceTmp["queryString"]["costumer_address"]["city"]=searchText;
              }else if(query_type=="state"){
                invoiceTmp["queryString"]["costumer_address"]["state"]=searchText;
              }else if(query_type=="zipcode"){
                invoiceTmp["queryString"]["costumer_address"]["zipcode"]=searchText;
              }
   }


   // Invoice Filter
   if(["id", "reference"].includes(query_type)){
     if(query_type=="id"){
       invoiceTmp["queryString"]["invoice"]["id"]=searchText;
     }else if(query_type=="reference"){
       invoiceTmp["queryString"]["invoice"]["reference"]=searchText;
     }
   }


  return invoiceTmp;

}

useEffect(() => {

  LoadStatusList();
  LoadMyClients();
},[]);

const LoadStatusList = async (page_arg=null, limit_arg=null) => {
    
  let response = await getApi("/invoice/status/list",{ });
  setStatusList(response)
};




const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    
  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi2("/invoice/list", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  FillArrayResult(response.lista.map(x=> x.invoice));
  setLoadingList(false);

};

const ExportPDF = async (page_arg=null, limit_arg=null) => {
    
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/report/invoice", Arrayp);
  
  if(response){
    alert("Seu relatório está sendo processado.\nEstaremos enviando dentro de poucos segundos.")
  }

};

const FillArrayResult=(data)=>{
  let arrayTMP=[];
  data.forEach(function(curr, index, array){
    arrayTMP.push({
      amount:parseFloat(curr.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) ,
      amount_paid: parseFloat(curr.amount_paid?curr.amount_paid:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) ,
      amount_marked_paid:parseFloat(curr.amount_marked_paid?curr.amount_marked_paid:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) , 
      status:curr["status"],
      statusId:curr["status"]["id"],
      fullname:curr["costumer"]["fullname"],
      tax_identifier:curr["costumer"]["tax_identifier"],
      id:curr["id"],
      reference:curr["reference"],
      due_date:new Date(curr["due_date"]).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}),
      payment_date:curr.payment_date?new Date(curr["payment_date"]).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}):null
    })
    setListCobr(arrayTMP)
  });

}


  return (
    <React.Fragment>
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
      <Grid container spacing={6}>
                <Grid item md={6} style={{ minWidth: window.isNativeApp?"100%":"100px" }}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4} style={{ minWidth: window.isNativeApp?"100%":"100px" }}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
                          <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
                          <MenuItem value={"id"}>Código da Transação</MenuItem>
                          <MenuItem value={"reference"}>Código de Referência</MenuItem>
                          <MenuItem value={"email"}>E-mail</MenuItem>
                          <MenuItem value={"phone"}>Telefone</MenuItem>
                          <MenuItem value={"zipcode"}>Endereço - CEP</MenuItem>
                          <MenuItem value={"street"}>Endereço - Logradouro</MenuItem>
                          <MenuItem value={"district"}>Endereço - Bairro</MenuItem>
                          <MenuItem value={"city"}>Endereço - Cidade</MenuItem>
                          <MenuItem value={"state"}>Endereço - Estado</MenuItem>
                        
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>

              <Grid container spacing={6} >
                <Grid item md={2} style={{ minWidth: window.isNativeApp?"100%":"100px" }}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Status do pagamento Pagamento"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status do pagamento Pagamento"
                        value={search_Status}
                        onChange={e=> setSearchStatus(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos os Status </MenuItem>
                      {  statusList.map(el => (
                        <MenuItem value={el.id}>{el.extended_name}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2} style={{ minWidth: window.isNativeApp?"100%":"100px" }}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Filtrar por data"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Filtrar por data"
                        value={query_filterDateType}
                        onChange={e=> setQuery_filterDateType(e.target.value)}
                      >
                      <MenuItem value={1}>Emissão da cobrança </MenuItem>
                      <MenuItem value={2}>Aprovação da cobrança </MenuItem>
                      <MenuItem value={3}>Vencimento da cobrança </MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Inicial"}
                    variant="outlined"
                    style={{ minWidth: window.isNativeApp?"150px":"100px" }}
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateStart(e.target.value)}
                    value={query_filterDateStart}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Final"}
                    variant="outlined"
                    fullWidth
                    style={{ minWidth: window.isNativeApp?"150px":"100px" }}
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateEnd(e.target.value)}
                    value={query_filterDateEnd}
                  />
                </Grid>
                <Grid item md={3}>
                <Tooltip title="Inclui na listagem todos as cobranças, incluindo os arquivados."> 
                  <FormControlLabel
                      value={true}
                      checked={includeArchivedInvoice}
                      onClick={e=> setIncludeArchiveddInvoice(!includeArchivedInvoice)}
                      control={<Switch color="primary" />}
                      label="Incluir cobranças arquivados"
                      labelPlacement="Incluir cobranças arquivados"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              {
             window.isNativeApp && <Box style={{marginTop:10}}></Box>
          }
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (window.isNativeApp?null:600), width: "100%" }}>
     

          <DataGridImprovedCobList
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={window.isNativeApp?25:limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}
            
          />
              <Divider my={3} ></Divider>
      
<EditClient
            selectedValue={selectedEditValue}
            open={openEditClient}
            onClose={handleCloseEditClient}
          />
      
        </div>
       
      </Paper>
     
          
    </Card>
    <Grid container justifyContent="right" >
    <Button onClick={()=> ExportPDF()} color={"error"} variant="outlined">Exportar PDF</Button>
  </Grid>
  </React.Fragment>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Cobranças" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Minhas cobranças
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
          Cobranças
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
    
         <MenuCreateBillComponent />
        </Grid>
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
      
  


       
    </React.Fragment>
  );
}

export default DataGridPage;
