import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Divider,
  CircularProgress,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../assets/styles/datagrid-improved.css"
import { Box } from "@mui/system";


function DataGridImprovedClientList({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,  
  handleClickOpenEditPortalClient,
  handleClickOpenEditClient,
  deleteUser
}) {


  return (
    <React.Fragment>

          
{window.isNativeApp && 
loading && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

    {!window.isNativeApp &&
        <DesktopTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        
      />
      }

    {window.isNativeApp &&
        <MobileTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        handleClickOpenEditPortalClient={handleClickOpenEditPortalClient}
        handleClickOpenEditClient={handleClickOpenEditClient}
        deleteUser={deleteUser}
      />
      }

    </React.Fragment>

  );
}


function DesktopTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading
}) {
  return (
    <React.Fragment>
     <DataGrid
            rowsPerPageOptions={rowsPerPageOptions}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            page={page}
            onPageSizeChange={onPageSizeChange}
            paginationMode={'server'}
            onPageChange={onPageChange }
            loading={loading}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum cliente encontrado.
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
          />
      

    </React.Fragment>

  );
}

function MobileTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,
  handleClickOpenEditPortalClient,handleClickOpenEditClient,deleteUser
}) {
  const navigate = useNavigate();
  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }
 

   //  console.log( Object.getOwnPropertyNames(row));
     // console.log(row);
   
  
  return (
    <React.Fragment>

    <section id="no-more-tables">
    
<table>
	<thead>
		<tr>
      {columns.map(el => 
      	<th>{el.headerName}</th>
      )
    }
			
		</tr>
	</thead>
	<tbody>
  {
    rows.map( elc =>
		<tr style={{
      marginTop:15
    
    }}>
 
   
      <td data-title={'Referência' }>{elc.reference??' - '}</td>
      <td data-title={'Nome Completo' }>{elc.fullname?elc.fullname:" - "}</td>
      <td data-title={'CPF/CNPJ' }>{elc.tax_identifier}</td>
      <td data-title={'E-mail' }>{elc.email??' - '}</td>
      <td data-title={'Telefone' }>{elc.phone??'- '}</td>
      
      <td data-title={'Ação' } style={{marginTop:5}}>
      <React.Fragment>
          <Grid item sx={{marginBottom:10}}>
            <Button
              variant="contained"
              size="small"
              color={"primaryboot"}
              onClick={e => navigate("/meus-clientes/visualizar/"+elc.id)}>
              Visualizar
            </Button>
    
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={e=> handleClickOpenEditClient(elc)}>
              Editar
            </Button>
          </Grid>
         
        </React.Fragment>
      </td>
        
		</tr>
    )
  }
	</tbody>
</table>
<Divider my={3} ></Divider>


  <br></br>
  <br></br>
  <div class="pagination">
    
    <a href="#" onClick={e=> onPageChange(page-1)}>&laquo;</a>
    <a href="#">Página {page+1}</a>
    <a href="#" onClick={e=> onPageChange(page+1)}>&raquo;</a>

  </div>
</section>

    </React.Fragment>

  );
}


export default DataGridImprovedClientList;
