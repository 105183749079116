import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import PropTypes from 'prop-types';

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";
import EditableAddress from "./address";
import ListCustomFieldComponent from "../../CustomField/ListCustomFieldComponent";
import AttachmentsCostumerBusiness from "../show/AttachmentsCostumerBusiness";


function AddNewClient(props) {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, selectedValue, open } = props;
  let [identifier, setIdentifier] = useState('');
  let [fullname, setFullname] = useState('');
  let [email, setEmail] = useState('');
  let [reference, setReference] = useState('');
  let [isEmail, setIsEmail] = useState(false);

  useEffect(() => {
    if(selectedValue.tax_identifier){
      setIdentifier(selectedValue.tax_identifier)
      setFullname(selectedValue.fullname);
     
      setEmail(selectedValue.email)
      setPhone(selectedValue.phone);
      setReference(selectedValue.reference);
      setId(selectedValue.id);
      setIsEmail(selectedValue.email?true:false)
    }
   


    const LoadDataCostumer=async ()=>{
      const response = await getApi("/costumer/"+selectedValue.id);
      
      if(response.costumer){
        setIsUserAddress(response.costumer.is_address_user);

        if(response.costumer.is_address_user==false){
          setZipCode(response.costumer_address.zipcode);
          setStreet(response.costumer_address.street);
          setComplement(response.costumer_address.complement);
          setNumber(response.costumer_address.number);
          setDistrict(response.costumer_address.district);
          setCity(response.costumer_address.city);
          setStateCountry(response.costumer_address.state);
        }
        setLoadingAddress(false)
        
      }
    }

    LoadDataCostumer();


  },[selectedValue]);

  const handleClose = () => {
    onClose(selectedValue);
    window.location.reload();

  };

  let [saving, setSaving] = useState(false);
  let [loadingAddress, setLoadingAddress] = useState(true);
  let [errormsg, setErrorMsg] = useState('');

  let [id, setId] = useState('');
  let [phone, setPhone] = useState('');

  let [is_user_address, setIsUserAddress] = useState(false);
  let [loadingcep, setLoadingCep] = useState(false);



  
let [city, setCity] = useState('');
let [stateCountry, setStateCountry] = useState('');
let [district, setDistrict] = useState('');
let [number, setNumber] = useState('');
let [street, setStreet] = useState('');
let [zipcode, setZipCode] = useState('');
let [complement, setComplement] = useState('');

const [tabVal, setTabVal] = React.useState(0);

const handleChangeTabVal = (event, newValue) => {
  setTabVal(newValue);
};

const serachZipCode=async ()=>{
  setLoadingCep(true)
  let zipcodeTmp=String(zipcode).replace(/\.|\-/g, '');

  if(zipcodeTmp.length!=8){
    setLoadingCep(false) 
    return false;
  }
 
  const response = await getApi("/consultar/cep/"+zipcodeTmp);

  if(!response){
    return;
  }

  setCity(response.city);
  setStreet(response.street);
  setDistrict(response.district)
  setStateCountry(response.state)
  setLoadingCep(false) 
 
}


const checkValues=()=>{
  if(!fullname){
    alert("Você precisa preencher o nome completo");
  }else if(!email && isEmail){
    alert("Você precisa preencher o seu e-mail");
  }
  else if(!identifier){
    alert("Você precisa preencher o CPF ou CNPJ");
  }else if(!phone){
    alert("Você precisa preencher o telefone");
  }
  
  else if(is_user_address==false && !zipcode){
    alert("Você precisa informar o cep");
  }else if(is_user_address==false && !street){
    alert("Você precisa informar o logradouro");
  }else if(is_user_address==false && (!number && number==0)){
    alert("Você precisa informar o numero");
  }else if(is_user_address==false && !district){
    alert("Você precisa informar o bairro");
  }else if(is_user_address==false && !city){
    alert("Você precisa informar o cidade");
  }else if(is_user_address==false && !stateCountry){
    alert("Você precisa informar o estado");
  }else{
    return true;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }

   let response = await postApi("/costumer/update/"+id,{
    email:isEmail?email:"naotememail@cachebank.com.br",
    fullname:fullname,
    phone:phone.replace(/\D/g, ""),
    tax_identifier:String(identifier).replace(/\D/g, ""),
    is_address_user:is_user_address,

    zipcode:zipcode.replace(/\D/g, ""),
    street:street,
    number:number,
    district:district,
    city:city,
    state:stateCountry,
    reference:reference

   });
 
  response_run(response);

};
const navigate = useNavigate();


const response_run=(response)=>{
  if(response.success){
    alert("Cliente atualizado com sucesso.");
    setSaving(false)
  }else{

    if(response.city){
      setErrorMsg(response.city.toString())
    }else if(response.state){
      setErrorMsg(response.state.toString())
    }else if(response.district){
      setErrorMsg(response.district.toString())
    }else if(response.street){
      setErrorMsg(response.street.toString())
    }else if(response.number){
      setErrorMsg(response.number.toString())
    }else if(response.complement){
      setErrorMsg( response.complement.toString())
    }else if(response.zipcode){
      setErrorMsg( response.zipcode.toString())
    }
    else if(response.country){
      setErrorMsg( response.country.toString())
    }
    else if(response.email){
      setErrorMsg(response.email.toString())
    }else if(response.fullname){
      setErrorMsg(response.fullname.toString())
    }else if(response.password){
      setErrorMsg(response.password.toString())
    }else if(response.phone){
      setErrorMsg(response.phone.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}

  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Cliente - {fullname}</center></DialogTitle>
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Nome Cliente *</label>
                  <TextField
                    id="fullname"
                    placeholder={"Nome ou razão social"}
                    variant="outlined"
                    value={fullname}
                    onChange={e => setFullname(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>

                <label for="my-input">CPF/CNPJ *</label>
                <InputMask
                  mask={String(identifier.replace(/\D/g, "")).length<=11?"999.999.999-999":"99.999.999/9999-99"}
                  disabled={false}
                  maskChar="_"
                  placeholder="CPF/CNPJ"
                  value={identifier}
                  fullWidth
                  my={2}
                  onChange={e => setIdentifier(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cpf_cnpj"
                  type="text"
                  id="cpf_cnpj"
                  autoComplete="cpf_cnpj"
                  placeholder="CPF/CNPJ"
                />}
                </InputMask>

                </Grid>
              </Grid>
            

              <Grid container spacing={6}>
              <Grid item sm={12} xl={6} >
                {isEmail &&
                <React.Fragment>
                  <label for="my-input">Endereço de e-mail *</label>
                  <TextField
                    id="fullname"
                    placeholder={"E-mail"}
                    variant="outlined"
                    value={email}
                    fullWidth
                    onChange={e => setEmail(e.target.value)}
                    my={2}
                    type="email"
                  />
                </React.Fragment>
                }
                 <label for="my-input">Cliente não possui e-mail?</label>
                <Checkbox checked={!isEmail } onClick={e=> setIsEmail(!isEmail)} />
                </Grid>

                <Grid item sm={12} xl={6}>
                <label for="my-input">Telefone *</label>
                <InputMask
                  mask={ ( String(String(phone).replace(/\D/g, "")).substring(2,3) >=5 )?"(99) 9 9999-9999":"(99) 9999-9999"}
                  disabled={false}
                  maskChar="_"
                  placeholder="Telefone"
                  value={phone}
                  fullWidth
                  my={2}
                  onChange={e => setPhone(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  autoComplete="phone"
                  placeholder="Telefone"
                />}
                </InputMask>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Referência</label>
                  <TextField
                    id="reference"
                    placeholder={"Referência do cliente"}
                    variant="outlined"
                    value={reference}
                    onChange={e => setReference(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

                
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabVal} onChange={handleChangeTabVal} 
  variant="scrollable"
  scrollButtons="auto"
  allowScrollButtonsMobile>
                    <Tab label="Endereço do cliente"  />
                    <Tab label="Campo Personalizado"  />
                    <Tab label="Arquivos"  />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabVal} index={0}>
                    <EditableAddress
                    
                    loadingAddress={loadingAddress}
                    is_user_address={is_user_address}
                    setIsUserAddress={setIsUserAddress}
                    setZipCode={setZipCode}
                    zipcode={zipcode}
                    serachZipCode={serachZipCode}
                    loadingcep={loadingcep}
                    setStreet={setStreet}
                    street={street}
                    setNumber={setNumber}
                    number={number}
                    setComplement={setComplement}
                    complement={complement}
                    setDistrict={setDistrict}
                    district={district}
                    setCity={setCity}
                    city={city}
                    stateCountry={stateCountry}
                    setStateCountry={setStateCountry}
                  
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabVal} index={1}>
                  <ListCustomFieldComponent reference_identifier={id} custom_field_type={1} hideTitle={true} />
                </CustomTabPanel>
                <CustomTabPanel value={tabVal} index={2}>
                  <AttachmentsCostumerBusiness key={"attach"+tabVal}  costumer={selectedValue} embeded={true} />
                </CustomTabPanel>
              </Box>
                
              
              

            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              <br></br>
              <Button variant="contained" disabled={saving || loadingAddress} color="primary" mt={3} onClick={handleSubmit} >
                Atualizar Dados
              </Button>
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                




    </Dialog>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default AddNewClient;
