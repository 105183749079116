import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select } from '@mui/material';
import {mesesAno} from "../../../mesesano";
import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.isNativeApp?"90%":500,
  bgcolor: 'background.paper',
  border: '1px solid #040e13',
  boxShadow: 10,
  overflow: 'scroll',
  overflowY: 'scroll',
  p: 4,
};

export default function SignaturePrintInvoices({setOpen, open,signatureM,reload}) {
  let { putApi,getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,ShowModalFunc } = useAuth();


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reload(false);
  }

  let [signatureId, setSignatureId] = useState(signatureM.id);

  let [saving, setSaving] = useState(false);
  let [upToMonth, setUpToMonth] = useState(new Date().getMonth()+1);
  let [upToYear, setUpToYear] = useState(new Date().getFullYear()+1);

  const HandleSubmit = async () => {
    setSaving(true);
    let submitPost={
      year:upToYear,
      month:upToMonth
    };
    let response = await postApi("/invoice/signature/print/"+signatureId,submitPost);
    setSaving(false);
    if(response.error){
      alert(response.error.toString());
      return ;
    }else if(response.link){
      window.open(response.link); 
    }
  };

  return (
    <div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <Typography variant="h5" component="inline">
              <center> Impressão do Carnê: <br></br>
              </center>
          </Typography>

          <Typography sx={{ fontSize: window.isNativeApp?12:14 }} mt={1} mb={2}>
              Detalhes da Assinatura
            </Typography>
          <Typography variant="caption"  color="text.secondary" gutterBottom mt={2}>
              Status Atual: {signatureM.status.name}
              <br/>
              Cliente: {signatureM.costumer.fullname} - {signatureM.costumer.tax_identifier}
              <br/>
              Parcelas Geradas: {signatureM.installments_generated}
              <br/>
          </Typography>

         

          <Grid container mt={4}>
            <Typography variant="h6" gutterBottom mt={2}>
            Selecione até quando você quer gerar o carnê:
            </Typography>

            <Grid item sm={12}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Mês
              </InputLabel>
              <Select
              fullWidth
                value={upToMonth}
                onChange={e=> setUpToMonth(e.target.value)}
                sx={{
                  textAlignLast:"center"
                }}
              >
                {  mesesAno.meses.map(el => (
                  <MenuItem  value={el.id}>{el.name}</MenuItem >
                ))}
              </Select>
            </Grid>
            <Grid item sm={12} mt={2}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Ano
              </InputLabel>
              <Select
              fullWidth
                value={upToYear}
                onChange={e=> setUpToYear(e.target.value)}sx={{
                  textAlignLast:"center"
                }}
              >
                 <MenuItem  value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem >
                 <MenuItem  value={new Date().getFullYear()+1}>{new Date().getFullYear()+1}</MenuItem >
              </Select>
            </Grid>
            
          </Grid>

          <center>
      <Button sx={{marginTop:5}}
        variant="contained"
        size="medium"
        color={'boot_primary'} 
        disabled={saving}
        onClick={e=> HandleSubmit()} 
      >
       {saving?"Gerando parcelas do carnê":"Imprimir Carnê"} 
      </Button>
      <Grid container justifyContent="center" >
              <Grid item>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
      </center>

      <Grid>
      <Button 
        variant="contained"
        size="small"
        color={'secondary'}  disabled={saving}
        onClick={e=> handleClose()}
      >
        Fechar
      </Button>
      </Grid>


        </Box>
      </Modal>
    </div>
  );
}