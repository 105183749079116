import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Work from '@mui/icons-material/Work';
import {
  DollarSign,
} from "react-feather";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography, 
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TableContainer,
  TableBody,
  Table,
  TableCell,
  Paper as MuiPaper,
  TableRow,
  Button,
  Tabs,
  Tab,
  Popover,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);


const Paper = styled(MuiPaper)(spacing);



function SignatureItensComponent({signatureM,reload}) {
    let { getApi,postApi,deleteApi } = useAuth();
  
  
    const [openEditable, setOpenEditable] = React.useState(false);
    const [tabSignature, setTabSignature] = React.useState('invoices');
    const [anchorNextInvoice, setAnchorNextInvoice] = React.useState(null);
  
  
    const handleTabSignature = (event, newValue) => {
      setTabSignature(newValue);
    };
  
  
    return (

      <React.Fragment>
      
      <Grid  container justifyContent="center">

        <Grid sm={12} xl={4} mt={3} sx={{borderRight: '4px solid #eaeaea', marginRight:2}}>
     
        <TableContainer component={Paper} >
           
           <Table  size="medium" aria-label="a dense table">
             <TableBody>
            
                 <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily, textAlign:"center"}}>
                 <strong>Descrição</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                <strong>Qtd</strong>
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                <strong>Valor</strong>
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                <strong>SubTotal</strong>
                </TableCell>
              </TableRow>

              <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                 <TextField
                  fullWidth
                  id="referenceField"
                  label="Descrição"
                />
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                Qtd
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                Valor
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                Valor
                </TableCell>
              </TableRow>

            </TableBody>  
          </Table>   
        </TableContainer>   
      </Grid>
  </Grid>   

      </React.Fragment>

  );
}

export default SignatureItensComponent;
