import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import IndexDesktop from "./SignatureComponent"
import {
  Avatar as MuiAvatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography as MuiTypography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import EditSignature from "./edit";


const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;



function SignatureView() {
  let { getApi,postApi } = useAuth();

  useEffect(() => {

    loadSignature();
  }, []);

  
  const { id } = useParams();
  
  const [signatureM, setSignatureM] = useState([]);
  const [loading, setLoading] = React.useState(false);


  const  loadSignature = async(reloadPage=true) => {
    if(reloadPage){
      setLoading(true);
    }
    
    let response = await getApi("/invoice/signature/"+id);
    setSignatureM(response.signature);
    if(reloadPage){
      setLoading(false);
    }
  }

  const reload = (reloadPage=true)=>{
    loadSignature(reloadPage)
  }

  return (
    <React.Fragment>
      { signatureM.id && !loading && <IndexDesktop signatureM={signatureM} reload={reload} ></IndexDesktop>}
      {loading && 
            <Grid item>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Grid>
        }
    </React.Fragment>
  );
}

export default SignatureView;
