import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Grid, Link, Paper, Typography } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";
import useAuth from "../../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  const { isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if(String(location.pathname).includes("logout")){
      window.location.href="/login";
    }
   
    if (isInitialized && isAuthenticated) {
      window.location.href="/app/access/check";
    }
    

  }, [isAuthenticated,isInitialized]);

  

  return (
    <React.Fragment>
      
      <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
      <Wrapper>
        <Helmet title="Sign In" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Portal do cliente
        </Typography>


        <Typography component="h2" variant="body1" align="center">
          Para continuar, informe os seus dados de acesso abaixo.
        </Typography>

        <SignInComponent />
        <Grid container justifyContent="flex-center">

              <Grid mt={5} item>
                <Link href="/criar-conta" variant="body2">
                  Não possui uma conta? Abra sua agora.
                </Link>
              </Grid>
            </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
