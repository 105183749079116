import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  LinearProgress,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";

function MenuCreateBillComponent() {
  let { ShowModalFunc,showModal,isInitializedBS,businessAccount } = useAuth();

  const [selectedValue, setSelectedValue] = React.useState([]);

  const handleClose = (value) => {
    ShowModalFunc(null)
  };

  return (
    <React.Fragment>

<div>
          {!window.isNativeApp &&
          <React.Fragment>

            <Button variant="contained" color="primary" onClick={e=> ShowModalFunc("emitr_assinatura")}  disabled={isInitializedBS==false}>
              <AddIcon />
              Emitir Assinatura
            </Button>
            
            <Button variant="contained"  color="primary" sx={{marginLeft:5}} onClick={e=> ShowModalFunc("emitr_boleto")} disabled={isInitializedBS==false}>
              <AddIcon />
              Emitir boleto
            </Button>
            <Button variant="contained" color="primary" sx={{marginLeft:5}} onClick={e=> ShowModalFunc("emitr_carne")} disabled={isInitializedBS==false}>
              <AddIcon />
              Emitir Carnê
            </Button>
            {businessAccount.allowed_charge_types &&
              businessAccount.allowed_charge_types.includes(2) &&
            <Button variant="contained" color="primary" sx={{marginLeft:5}} onClick={e=> ShowModalFunc("emitr_pix")} disabled={isInitializedBS==false}>
              <AddIcon />
              Emitir Pix
            </Button>
            }
            {isInitializedBS==false &&
                <LinearProgress />}


                
          </React.Fragment>}
        {window.isNativeApp &&
            <React.Fragment>

        <Button variant="contained" size={"small"} color="primary" onClick={e=> ShowModalFunc("emitr_assinatura")} disabled={isInitializedBS==false}>
          <AddIcon />
          Emitir Assinatura
        </Button>

        <Button variant="contained" size={"small"} color="primary" sx={{marginLeft:1}} onClick={e=> ShowModalFunc("emitr_boleto")} disabled={isInitializedBS==false}>
          <AddIcon />
          Emitir boleto
        </Button>
        <Button variant="contained" size={"small"} color="primary" sx={{marginLeft:1}} onClick={e=> ShowModalFunc("emitr_carne")} disabled={isInitializedBS==false}>
          <AddIcon />
          Emitir Carnê
        </Button>
        {
          businessAccount.allowed_charge_types &&
          businessAccount.allowed_charge_types.includes(2) &&
          <Button variant="contained" size={"small"} color="primary" sx={{marginLeft:1}} onClick={e=> ShowModalFunc("emitr_pix")} disabled={isInitializedBS==false}>
          <AddIcon />
          Emitir Pix
        </Button>
          }
       
            {isInitializedBS==false &&
                <LinearProgress />}


                
      </React.Fragment>}
          </div>

    </React.Fragment>
  );
}


export default MenuCreateBillComponent;
