import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


function Details() {
    let { getApi,postApi,user,businessAccount ,getStoreApi,postStoreApi } = useAuth();

    const [logoMarcaUrl, setLogoMarcaUrl] = React.useState(null);
    const [logoMarcaBase64, setLogoMarcaBase64] = React.useState(null);

    
    const [choosedNew, setChoosedNew] = React.useState(false);
    const [loadingLogoMark, setLoadingLogoMark] = React.useState(true);

    
    const [loadingAction, setLoadingAction] = React.useState(false);

    
useEffect(() => {
    Get()
}, []);
    
  const SearchFoto = async (e) => {
    
    setLogoMarcaUrl(URL.createObjectURL(e.target.files[0]));
    setChoosedNew(true);

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function(e) {
        let base64Image = String(reader.result).replace(/^data:image\/[a-z]+;base64,/, "");
        setLogoMarcaBase64(base64Image);
        

    };
  
  };

  const Remove = async (e) => {
    setLoadingAction(true);
    if(window.confirm("Você realmente deseja remover a logomarca?")){
        let response = await postStoreApi("/logomark/remove");
    }
    setLoadingAction(false);
    Get()
  };

  const Save = async (e) => {
    
    setLoadingAction(true);
    let response = await postStoreApi("/logomark/update",{
        logomark:logoMarcaBase64
    });
    setChoosedNew(false)
  
    setLoadingAction(false);
  };
  

  const Get = async (e) => {
    let response = await getStoreApi("/configuration");
    if(response.configuration){
      setLogoMarcaUrl(response.configuration.brandmark)
      setLoadingLogoMark(false);
    }
  
  };

 

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom textAlign={'center'}>
          Use a sua logomarca
        </Typography>

        <Spacer mb={4} />

        <Centered>
        {loadingLogoMark &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
        {!loadingLogoMark &&
        
        <img alt="LogoMarca" src={logoMarcaUrl?logoMarcaUrl:"https://store16.cachebank.com.br/43213152a5de22032efe0be784cf8f37.jfif"} width='230' height='90' />}
          <Typography variant="body2" component="div" gutterBottom>
          <Box fontWeight="fontWeightMedium">Ela será exibida em seu portal do cliente.</Box>
          <Box fontWeight="fontWeightMedium">Caso não seja definida, será exibido o nome do seu negócio.</Box>
         
          </Typography>

          <Button mr={2} variant="contained" color="primary" size="small" type="file" htmlFor="fileuploadlogomarca" >
            <label className="custom-file-label" htmlFor="fileuploadlogomarca">Fazer upload</label>
           
          </Button>
        <input type="file" className="custom-file-input" id="fileuploadlogomarca" accept="image/*" style={{display:"none"}} onChange={SearchFoto} accept="image/jpg, image/jpeg,image/png" />


          
          <Typography variant="body2" component="div" gutterBottom style={{marginTop:20}}>
          <Button mr={2} variant="contained" color="primary" size="small" disabled={choosedNew==false || loadingAction==true } onClick={Save}>
            Salvar
          
          </Button>
          <Button mr={2} variant="contained" color="boot_danger" size="small" disabled={!logoMarcaUrl || loadingAction==true} onClick={Remove}>
            Remover
       
          </Button>
          {loadingAction &&
              <Box sx={{ display: 'flex', alignContent:"center" }}>
                <CircularProgress />
              </Box>}
         </Typography>
        
        </Centered>
       
      </CardContent>
    </Card>
  );
}



function LogoMarkAllStoreComponent() {
  return (
    <React.Fragment>

      <Grid container >
        <Grid item xs={12} lg={12} xl={12}>
          <Details />
        </Grid>
       
      </Grid>
    </React.Fragment>
  );
}

export default LogoMarkAllStoreComponent;
