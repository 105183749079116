import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { CircularProgress, Input } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TblDocumentos from "./TblDocumentos";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { Label } from "@mui/icons-material";
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Avatar from '@mui/material/Avatar';
import useAuth from "../../../hooks/useAuth";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function UploadDocsComponentMobile({requestedDoc,datachange, isToSend,LoadListDocumentPeding,setIsToSend}) {
  let { getApi,postApi } = useAuth();

  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const [listUploadVal, setListUploadVal] = React.useState([]);

  const [savingId, setsavingId] = React.useState(false);

  
  const UploadComponentUp= async(e)=>{
    if(requestedDoc.document_type["max_upload"]==listUploadVal.length){
      alert("Você só pode enviar até "+requestedDoc.document_type["max_upload"]+" arquivos");
      return false;
    }
    let filename=e.target.files[0].name;

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function(e) {
        let base64Image = reader.result;

        let upTMP={
          "key":Math.floor(Math.random() * 100),
          "iddoc": requestedDoc.id,
          "file":String(base64Image).replace(/^data:image\/[a-z]+;base64,/, "")
          .replace('data:', '')
          .replace(/^.+,/, ''),
          "name":filename
        }
    
        let tmpVLa=([... listUploadVal, upTMP  ]);
    
        setListUploadVal(tmpVLa );
        datachange(tmpVLa)
    };

    
   
  }

  const RemoveUploadComponentUp= (list, value)=> {
  
    let indexOfTmp=list.indexOf(value);
    list=list.splice(indexOfTmp>0?indexOfTmp:1, 1);

    setListUploadVal(list);
    datachange(list)
  }

  
  useEffect(() => {
    async function fetchData() {

      if(isToSend==true && listUploadVal.length>0){

        let files=[];
        listUploadVal.forEach((item,index, arr)=>{
          files.push(item.file);
        } )


        setsavingId(true);


        if(files.length>requestedDoc.document_type["max_upload"]){
          alert("Você deve enviar até "+requestedDoc.document_type["max_upload"]+" arquivos.");
          
        setsavingId(false);
        setIsToSend(false);
          return false;
        }else if(requestedDoc.document_type["min_upload"]>files.length){
          alert("Você deve enviar no minimo "+requestedDoc.document_type["min_upload"]+" arquivos.");
          
        setsavingId(false);
        setIsToSend(false);
          return false;
        }

        let response = await postApi("/document/upload", {
          document_requests_documents_id:requestedDoc.id,
          file:files
        })

        if(response.success==true){
          LoadListDocumentPeding().then(x => {
            setsavingId(false)
            
        setIsToSend(false);
           })
    
            
        }else{

          setsavingId(false)
          
        setIsToSend(false);
        }

      }
    }
    fetchData();
  },[isToSend]);




  return (
    <React.Fragment>

  
            {requestedDoc.document_type["max_upload"]>listUploadVal.length &&
              ![1,2].includes(requestedDoc.document_type["id"]) &&
            <React.Fragment>
             
            <Button variant="contained" component="label" onChange={e=> UploadComponentUp(e)}  >
              Anexar arquivo
              <input hidden type="file"  accept="image/*,application/pdf" />
            </Button>
            </React.Fragment>
            }

            {requestedDoc.document_type["max_upload"]>listUploadVal.length &&
            [1,2].includes(requestedDoc.document_type["id"]) &&
            
            <Button variant="contained" component="label" onChange={e=> UploadComponentUp(e)}  >
              Capturar Foto
              <input hidden accept="image/*;capture=camera" type="file"  accept="image/*" capture="user"  />
            </Button>
            }
            

            
            <List dense={dense}>
            {savingId &&
             <CircularProgress color="secondary" />
            }

            {listUploadVal.map(elc => (
              savingId==false && 
                <ListItem 
                secondaryAction={
                  <IconButton  edge="end" aria-label="delete" onClick={e => RemoveUploadComponentUp(listUploadVal, elc)}>
                    <DeleteIcon />
                  </IconButton>
                } >
                <ListItemAvatar>
                  <Avatar>
                    <FileCopyIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={elc.name}
                  secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            ))}
                
            </List>
  

    </React.Fragment>
  );
}

export default UploadDocsComponentMobile;
